import {
  WordpressPano,
  Pano,
  Hotspot,
  LayerGroup,
  PanoScenario,
  PanoLabel,
  Scenario,
  PanoLink,
  PanoHotspotTypeMap,
  PanoAction,
  PanoActionTypeMap,
  WordpressPanoAction,
  WordpressPanoActionTypeMap,
  WordpressHotspot,
  WordpressPointHotspot,
  WordpressPolygonHotspot,
  WordpressPolylineHotspot,
  PanoPoi,
} from "../types";
import { decodeEntities } from "@wordpress/html-entities";
import { getTokenValue } from "../TokenStyles";
import { MarkerTypes } from "../shared/components/MarkerImages";
import { generateLabelURI, generatePolygonShapeURI } from "./panoXmlUtils";
import { upsert } from "./areaUtils";
import { compressToEncodedURIComponent } from "lz-string";

/* eslint-disable import/no-webpack-loader-syntax, import/no-unresolved */
import videoMarkerHigh from "!!raw-loader!../images/markers/poiVideoHigh.svg";
import userPin from "!!raw-loader!../images/markers/pinUser.svg";
import videoMarker from "!!raw-loader!../images/markers/poiVideo.svg";
import photoMarker from "!!raw-loader!../images/markers/poiPhoto.svg";
import photoMarkerHigh from "!!raw-loader!../images/markers/poiPhotoHigh.svg";
import vcrMarker from "!!raw-loader!../images/markers/poiVcr.svg";
import vcrMarkerHigh from "!!raw-loader!../images/markers/poiVcrHigh.svg";
import defaultMarker from "!!raw-loader!../images/markers/pinDefault.svg";
import editorMarker from "!!raw-loader!../images/markers/poiDefault.svg";
import close from "!!raw-loader!../images/vr/close.svg";
import arrowDown from "!!raw-loader!../images/vr/arrow-down.svg";
import arrowUp from "!!raw-loader!../images/vr/arrow-up.svg";
import { KrpanoElement } from "../libs/krpano";
import { PanoLocationPinModel } from "../tim-survey/EndUserApi";
import { LocalPinModel } from "../SurveyContext";
/* eslint-enable import/no-webpack-loader-syntax, import/no-unresolved */

export const krpanoId = "krpano";
const videoExtensions = ["mp4", "avi", "mpg", "m4v", "mov", "wmv", "webm", "mkv"];
const audioExtensions = ["m4a", "mp3", "aac", "wav", "oga", "ogg"];

function transformWordpressPano(
  data: WordpressPano,
  layerGroups: LayerGroup[],
  scenarios: Scenario[],
  allPanos: WordpressPano[]
): Pano {
  const pitch = Number(data.acf.pitch);
  const yaw = Number(data.acf.yaw);
  const fov = Number(data.acf.fov) || 70;
  const north = data.acf.north.length ? Number(data.acf.north) : undefined;
  const direction = north ? yaw - north : undefined;
  const layers = data.acf.map_location?.layers || [];
  const isIndoor = data.acf.indoor_outdoor === "indoor";

  const panoScenarios = data.acf.scenarios
    .map((scenario) => {
      let isVideo = false;
      if (scenario.resource) {
        if (videoExtensions.includes(getExtension(scenario.resource[0].toLowerCase())))
          isVideo = true;
      }
      const requestedScenario = scenarios.find((s) => s.id === scenario.scenario);
      return (
        requestedScenario &&
        ({
          isVideo,
          resource: scenario.resource,
          id: requestedScenario.id,
          slug: requestedScenario.slug || `draft-${requestedScenario.id}`,
          title: requestedScenario.title || `Scenario ${requestedScenario.id}`,
          pois: [],
          actions: [],
          showInNavigation: requestedScenario.showInMenu,
        } as PanoScenario)
      );
    })
    .filter(Boolean) as PanoScenario[];

  const panoLinks: PanoLink<keyof PanoHotspotTypeMap>[] = data.acf.panorama_location
    ? data.acf.panorama_location
        .map<PanoLink<keyof PanoHotspotTypeMap> | undefined>((panoLocation) => {
          const requestedPano = allPanos.find((pano) => pano.id === panoLocation.panorama);

          if (!requestedPano) return undefined;

          if (isWordpressPointHotspot(panoLocation)) {
            return {
              id: panoLocation.panorama,
              slug: requestedPano.slug || `draft-${requestedPano.id}`,
              type: "point",
              coordinates: [
                {
                  yaw: Number(panoLocation.yaw),
                  pitch: Number(panoLocation.pitch),
                },
              ],
              color: panoLocation.fill_color || "--color-primary-50",
            };
          }
          const coordinates = panoLocation.hotspot_coordinates.map(({ yaw, pitch }) => ({
            yaw: Number(yaw),
            pitch: Number(pitch),
          }));

          const border = {
            color: panoLocation.border_color || "--color-black",
            opacity: panoLocation.hasOwnProperty("border_opacity")
              ? Number(panoLocation.border_opacity)
              : Number(!!panoLocation.border_color),
            width: panoLocation.hasOwnProperty("border_width")
              ? Number(panoLocation.border_width)
              : 1,
          };

          if (isWordpressPolygonHotspot(panoLocation)) {
            return {
              id: panoLocation.panorama,
              slug: requestedPano.slug || `draft-${requestedPano.id}`,
              type: "polygon",
              coordinates,
              border,
              fill: {
                color: panoLocation.fill_color ?? "--color-white",
                opacity: panoLocation.fill_opacity ? Number(panoLocation.fill_opacity) : 0,
              },
              allowHighlight: true,
            };
          }

          return {
            id: panoLocation.panorama,
            slug: requestedPano.slug || `draft-${requestedPano.id}`,
            type: "polyline",
            coordinates,
            border,
          };
        })
        .filter((x): x is PanoLink<keyof PanoHotspotTypeMap> => Boolean(x))
    : [];

  // Add pano pois to scenario's in the map
  layerGroups
    .filter((l) => layers.includes(l.id))
    .forEach((layerGroup) => {
      layerGroup.scenarios.forEach((scenario) => {
        const requestedScenario = panoScenarios.find((s) => s.id === scenario.id);

        let type = requestedScenario?.isVideo ? MarkerTypes.Video : MarkerTypes.Pano;
        if (data.acf.icon_type && data.acf.icon_type !== "auto") {
          type = data.acf.icon_type as MarkerTypes;
        }

        if (!data.acf.map_location) return;

        upsert(["slug", "type"], scenario.pois, {
          slug: data.slug || `draft-${data.id}`,
          latitude: Number(data.acf.map_location.latitude),
          longitude: Number(data.acf.map_location.longitude),
          direction,
          height: data.acf.type,
          title: decodeEntities(data.title.rendered),
          type,
          color: requestedScenario ? data.acf.map_location.color : "--color-neutral-50",
          phantom: !requestedScenario,
        });

        // Push high pins on foreground for the map
        scenario.pois.sort((a, b) => {
          return Number(a.height === "high") - Number(b.height === "high");
        });
      });
    });

  const labels: PanoLabel[] = data.acf.labels
    ? data.acf.labels.map((label, i) => ({
        id: `label-${data.slug}-${i}`,
        text: label.label_text,
        yaw: Number(label.yaw),
        pitch: Number(label.pitch),
      }))
    : [];

  const audio = data.acf.audio
    ? data.acf.audio
        .filter((x) => audioExtensions.includes(getExtension(x.file)))
        .sort(
          (a, b) =>
            audioExtensions.indexOf(getExtension(a.file)) -
            audioExtensions.indexOf(getExtension(b.file))
        )
        .map((x) => x.file)
        .join("|")
    : "";

  if (data.acf.action_hotspots) {
    panoScenarios.forEach((panoScenario) => {
      data.acf.action_hotspots
        .filter((a) => !a.scenarios || a.scenarios.includes(panoScenario.id))
        .forEach((panoAction, i) => {
          const action = {
            slug: `action-${panoAction.action}-${i}`,
            label: panoAction.label,
            action: panoAction.action,
            type: panoAction.hotspot_type ?? "point",
          } as PanoAction<keyof PanoActionTypeMap, keyof PanoHotspotTypeMap>;

          if (isWordpressSwitchScenarioHotspot(panoAction)) {
            const targetScenario = scenarios.find((s) => s.id === panoAction.target_scenario);
            if (!targetScenario) return;
            const a = action as PanoAction<"switch-scenario", keyof PanoHotspotTypeMap>;
            a.target = targetScenario;
          } else if (isWordpressOpenUrlHotspot(panoAction)) {
            const a = action as PanoAction<"open-url", keyof PanoHotspotTypeMap>;
            a.targetUrl = panoAction.target_url;
          } else if (isWordpressOpenThreeSceneHotspot(panoAction)) {
            const a = action as PanoAction<"open-three-scene", keyof PanoHotspotTypeMap>;
            const uncompressed = panoAction.target_scene.join("|");

            a.target = compressToEncodedURIComponent(uncompressed);
          } else {
            console.error(`Unsupported action: ${panoAction.action}`);
            return;
          }

          if (isWordpressPointHotspot(panoAction)) {
            const a = action as PanoAction<keyof PanoActionTypeMap, "point">;
            a.coordinates = [{ yaw: Number(panoAction.yaw), pitch: Number(panoAction.pitch) }];
            a.color = panoAction.fill_color ?? "--color-white";
          } else {
            const a = action as PanoAction<keyof PanoActionTypeMap, "polygon" | "polyline">;
            a.coordinates = panoAction.hotspot_coordinates.map(({ yaw, pitch }) => ({
              yaw: Number(yaw),
              pitch: Number(pitch),
            }));

            if (isWordpressPolygonHotspot(panoAction)) {
              (a as PanoAction<keyof PanoActionTypeMap, "polygon">).fill = {
                color: panoAction.fill_color ?? "--color-white",
                opacity: panoAction.fill_opacity ? Number(panoAction.fill_opacity) : 0,
              };
              (a as PanoAction<keyof PanoActionTypeMap, "polygon">).allowHighlight =
                panoAction.allow_highlight ?? true;
            }

            a.border = {
              color: panoAction.border_color || "--color-black",
              opacity: panoAction.hasOwnProperty("border_opacity")
                ? Number(panoAction.border_opacity)
                : Number(!!panoAction.border_color),
              width: panoAction.hasOwnProperty("border_width")
                ? Number(panoAction.border_width)
                : 1,
            };
          }

          upsert(["slug"], panoScenario.actions, action);
        });
    });
  }

  return {
    id: data.id,
    title: decodeEntities(data.title.rendered),
    content: data.content.rendered,
    excerpt: data.excerpt.rendered,
    slug: data.slug || `draft-${data.id}`,
    icon: data.acf.icon_type || "auto",
    featuredMedia: data.featured_media,
    scenarios: panoScenarios,
    repeatVideo: data.acf.video.repeat,
    showVideoControls: data.acf.video.show_controls,
    returnAtEndOfVideo: data.acf.video.return_at_end_of_video,
    showInNavigation: data.acf.show_in_navigation,
    type: data.acf.type,
    status: data.status,
    isIndoor,
    panoLinks,
    pitch,
    yaw,
    fov,
    latitude:
      data.acf.map_location?.latitude !== undefined && data.acf.map_location.layers !== false
        ? Number(data.acf.map_location.latitude)
        : undefined,
    longitude:
      data.acf.map_location?.longitude !== undefined && data.acf.map_location.layers !== false
        ? Number(data.acf.map_location.longitude)
        : undefined,
    north,
    labels,
    audio,
    repeatAudio: data.acf.repeat_audio,
  };
}

export function transformWordpressPanos(
  data: WordpressPano[],
  layerGroups: LayerGroup[],
  scenarios: Scenario[]
) {
  return sortByProperty(
    data.map((pano) => transformWordpressPano(pano, layerGroups, scenarios, data)),
    "title"
  );
}

function sortByProperty<T>(arr: T[], property: keyof T) {
  return arr.sort((a, b) => {
    if (a[property] < b[property]) {
      return -1;
    }
    if (a[property] > b[property]) {
      return 1;
    }
    return 0;
  });
}

/** @todo Add read more link (`<p><a class="read-more-link" href="${contentUrl || ""}">Lees meer </a></p>`) */
export const addTextHotspot = (
  krpano: KrpanoElement,
  hotspot: Hotspot,
  contentUrl: string | false
) => {
  removeHotspot(krpano, "text-hotspot");
  krpano.call(`addhotspot(text-hotspot)`);
  const hs = krpano.get(`hotspot[text-hotspot]`);
  if (!hs) return;
  const [yaw, pitch] = [
    hotspot.coordinates.reduce((a, c) => a + c.yaw, 0) / hotspot.coordinates.length,
    hotspot.coordinates.reduce((a, c) => a + c.pitch, 0) / hotspot.coordinates.length,
  ];
  hs.type = "text";
  hs.html = hotspot.excerpt;
  hs.css = `font-size:var(--font-size-base); color:var(--color-neutral-70); line-height:20px; font-family:var(--font-family-body)`;
  hs.bgcolor = "0xffffff";
  hs.bgalpha = 1;
  hs.bgroundedge = "3";
  hs.edge = "top";
  hs.renderer = "css3d";
  hs.autowidth = false;
  hs.width = 275;
  hs.handcursor = false;
  hs.bgshadow = "0 6 64 0x191825 0.1";
  hs.padding = "15 15";
  hs.zorder = 1;
  hs.ath = yaw;
  hs.atv = pitch;
  hs.oy = hotspot.type === "faq" ? 30 : 60;
  hs.scale = 1;

  return hs;
};

export const addHotspot = (krpano: KrpanoElement, hotspot: Hotspot, onClick: (hs: any) => void) => {
  krpano.call(`addhotspot(${hotspot.slug})`);
  const hs = krpano.get(`hotspot[${hotspot.slug}]`);

  if (!hs) return;

  let width = 30;
  switch (hotspot.type) {
    case "high":
      width = 44;
      break;
    case "faq":
      width = 25;
      break;
    case "low":
      width = 50;
      break;
  }

  if (hotspot.coordinates.length > 1) {
    const fillColor = getComputedStyle(document.documentElement)
      .getPropertyValue(hotspot.fill?.color ?? "--color-white")
      .replace("#", "0x");
    const fillOpacity = hotspot.fill?.opacity ?? 0;
    const borderColor = getComputedStyle(document.documentElement)
      .getPropertyValue(hotspot.border?.color ?? "--color-black")
      .replace("#", "0x");
    const borderOpacity = hotspot.border?.opacity ?? 0;
    const borderWidth = hotspot.border?.width ?? (hotspot.type === "polyline" ? 3 : 0);

    const topRight = hotspot.coordinates.reduce(
      (a, c) => ({
        yaw: Math.max(a.yaw, c.yaw),
        pitch: Math.min(a.pitch, c.pitch),
      }),
      { yaw: -Infinity, pitch: Infinity }
    );

    const topRightPoint = hotspot.coordinates.reduce((a, c) => {
      const currentClosestDistance = Math.sqrt(
        Math.pow(a.yaw - topRight.yaw, 2) + Math.pow(a.pitch - topRight.pitch, 2)
      );
      const currentDistance = Math.sqrt(
        Math.pow(c.yaw - topRight.yaw, 2) + Math.pow(c.pitch - topRight.pitch, 2)
      );
      return currentDistance < currentClosestDistance ? c : a;
    });

    hotspot.coordinates.forEach((point, vertexIndex) => {
      krpano.set(`hotspot[${hotspot.slug}].point[${vertexIndex}].ath`, point.yaw);
      krpano.set(`hotspot[${hotspot.slug}].point[${vertexIndex}].atv`, point.pitch);
      if (point === topRightPoint && hotspot.showBadge) {
        krpano.call(`addhotspot(${hotspot.slug}-badge)`);
        const badge = krpano.get(`hotspot[${hotspot.slug}-badge]`);
        if (badge) {
          const badgeSize = 20;
          badge.html = "1";
          badge.type = "text";
          badge.css = `font-size: ${getTokenValue(
            "--font-size-30"
          )}; font-weight: bold; color: ${getTokenValue(
            "--color-white"
          )}; text-align: center; line-height: ${badgeSize - 1}px;`;
          badge.bgroundedge = badgeSize / 2;
          badge.bgcolor = `${getTokenValue("--color-error-50").replace("#", "0x")}`;
          badge.width = badgeSize;
          badge.height = badgeSize;
          badge.oversampling = 5;
          badge.ath = topRightPoint.yaw;
          badge.atv = topRightPoint.pitch;
          badge.capture = false;
          badge.zorder = 51;
        }
      }
    });
    if (hotspot.allowHighlight || !fillOpacity) {
      hs.style = "poly_hotspot";
    }
    hs.polyline = hotspot.type === "polyline";

    if (fillOpacity || !hotspot.allowHighlight) hs.fillalpha = fillOpacity;
    hs.fillcolor = fillColor;
    hs.bordercolor = borderColor;
    hs.borderalpha = borderOpacity;
    hs.borderwidth = borderWidth;
    hs.zorder = hotspot.type === "polyline" ? 50 : 49;
    hs.scale = 1;
  } else {
    const { yaw, pitch } = hotspot.coordinates[0];

    hs.url = `data:image/svg+xml;utf8,${encodeURIComponent(hotspot.icon!)}`;
    hs.width = width;
    hs.height = "prop";
    hs.ath = yaw;
    hs.atv = pitch;
    hs.oy = hotspot.type === "faq" || hotspot.type === undefined ? -21 : 0;
    hs.onout = `tween(scale, 1); tween(hotspot[${hotspot.slug}-text].scale, 1)`;
    hs.capture = false;
    hs.onhover = `tween(scale, 1.1); tween(hotspot[${hotspot.slug}-text].scale, 1.1)`;
    hs.linkurl = hotspot.url ?? null;
    hs.onclick = hotspot.onClick ?? (() => onClick(hs));

    addLabelHotspot(krpano, hotspot, hs.onclick);
  }
};

const addLabelHotspot = (krpano: KrpanoElement, hotspot: Hotspot, onClick: () => void) => {
  krpano.call(`addhotspot(${hotspot.slug}-text)`);
  const labelHotspot = krpano.get(`hotspot[${hotspot.slug}-text]`);

  if (!labelHotspot) return;

  const label = generateLabelURI(hotspot.title);
  const medianYaw = hotspot.coordinates.reduce((a, c) => a + c.yaw, 0) / hotspot.coordinates.length;
  const minPitch = Math.max(...hotspot.coordinates.map((c) => c.pitch));

  labelHotspot.url = label.svg;
  labelHotspot.width = label.width;
  labelHotspot.height = "prop";
  labelHotspot.ath = medianYaw;
  labelHotspot.atv = minPitch;
  labelHotspot.onout = `tween(hotspot[${hotspot.slug}].scale, 1); tween(scale, 1)`;
  labelHotspot.onhover = `tween(hotspot[${hotspot.slug}].scale, 1.1); tween(scale, 1.1)`;
  labelHotspot.capture = false;
  labelHotspot.zorder = 51;
  labelHotspot.onclick = onClick;

  if (hotspot.coordinates.length > 1) {
    labelHotspot.edge = "top";
    labelHotspot.oy = 5;
  } else {
    labelHotspot.edge = "left";
    labelHotspot.ox = hotspot.type === "high" ? 22 : 25;
    labelHotspot.oy = hotspot.type === "faq" || hotspot.type === undefined ? -21 : 0;
  }
};

export const removeHotspot = (krpano: KrpanoElement, name: string) => {
  krpano.call(`removehotspot(${name})`);
};

export const addQuestionHotspot = (krpano: KrpanoElement, hotspot: Hotspot) => {
  removeQuestionHotspot(krpano);
  krpano.call(`addhotspot(question-hotspot)`);
  const hs = krpano.get(`hotspot[question-hotspot]`);
  if (!hs) return;
  const { yaw, pitch } = hotspot.coordinates[0];
  hs.type = "image";
  hs.width = 32;
  hs.height = "prop";
  hs.zorder = 1;
  hs.ath = yaw;
  hs.atv = pitch;
  hs.oy = -24;
  hs.url = hotspot.icon;
  hs.scale = 1;
};

export const addSurveyHotspot = (
  krpano: KrpanoElement,
  location: PanoLocationPinModel & LocalPinModel,
  onDragEnd: (id: number, latitude: number, longitude: number) => void,
  onClick: (id: number) => void,
  color?: string
) => {
  krpano.call(`addhotspot(survey-${location.id})`);
  const hs = krpano.get(`hotspot[survey-${location.id}]`);
  if (!hs) return;
  hs.onclick = () => onClick(location.id);
  hs.type = "image";
  hs.oversampling = 3;
  hs.width = 32;
  hs.height = "prop";
  hs.zorder = 1;
  hs.ath = location.longitude;
  hs.atv = location.latitude;
  hs.oy = -18;
  hs.url = `data:image/svg+xml;utf8,${encodeURIComponent(getSurveyPin(color))}`;
  hs.scale = 1;
  hs.ondown = "draghotspot()";
  hs.onup = () => onDragEnd(location.id, hs.atv, hs.ath);
};

export const removeQuestionHotspot = (krpano: KrpanoElement) => {
  krpano.call(`removehotspot(question-hotspot)`);
};

export const renderVrPoiHotspot = async (
  krpano: KrpanoElement,
  url: string,
  hotspot: Hotspot
): Promise<void> => {
  const activeHotspot = krpano.get(`hotspot[${hotspot.slug}]`);
  if (!activeHotspot) return;

  await new Promise<void>((resolve) => {
    krpano.call(`addhotspot(vr-poi)`);
    const hs = krpano.get(`hotspot[vr-poi]`);

    if (!hs) return;

    const { x: ath, y: atv } = activeHotspot.getcenter();

    hs.type = "image";
    hs.width = 400;
    hs.height = "prop";
    hs.zorder = 60;
    hs.ath = ath;
    hs.atv = atv;
    hs.edge = "center";
    hs.distorted = true;
    hs.enabled = true;
    hs.handcursor = false;
    hs.scale = 1;
    hs.vr = true;

    hs.onloaded = async () => {
      const totalHeight = hs.loader instanceof HTMLImageElement ? hs.loader.naturalHeight : hs.loader.height;
      const viewportWidth = hs.loader instanceof HTMLImageElement ? hs.loader.naturalWidth : hs.loader.width;
      const viewportToHotspotSizeRatio = viewportWidth / (hs.width as number);
      const viewportHeight = Math.min(300, totalHeight);
      const hasControllers = krpano.get("have_vr_controllers");
      const scrollDelta = hasControllers ? Math.ceil(viewportHeight / 2) : 10;

      let [left, top] = [0, 0];

      const applyCrop = () => (hs.crop = [left, top, viewportWidth, viewportHeight].join("|"));
      applyCrop();

      const scrollDown = () => {
        top = Math.max(0, Math.min(top + scrollDelta, totalHeight - viewportHeight));
        applyCrop();
      };
      const scrollUp = () => {
        top = Math.max(0, Math.min(top - scrollDelta, totalHeight - viewportHeight));
        applyCrop();
      };

      const vrPoiControlContainerShape = generatePolygonShapeURI([
        [0, 0],
        [36, 0],
        [36, viewportHeight / viewportToHotspotSizeRatio],
        [0, viewportHeight / viewportToHotspotSizeRatio],
      ]);

      krpano.call(`addhotspot(vr-poi-control-container)`);
      const vrPoiControlContainer = krpano.get(`hotspot[vr-poi-control-container]`)!;
      vrPoiControlContainer.type = "image";
      vrPoiControlContainer.width = vrPoiControlContainerShape.width;
      vrPoiControlContainer.height = "prop";
      vrPoiControlContainer.zorder = 60;
      vrPoiControlContainer.url = vrPoiControlContainerShape.svg;
      vrPoiControlContainer.ath = ath;
      vrPoiControlContainer.atv = atv;
      vrPoiControlContainer.ox = (hs.width as number) / 2;
      vrPoiControlContainer.edge = "left";
      vrPoiControlContainer.distorted = true;
      vrPoiControlContainer.enabled = true;
      vrPoiControlContainer.handcursor = false;
      vrPoiControlContainer.scale = 1;
      vrPoiControlContainer.vr = true;

      if (totalHeight > viewportHeight) {
        const [vrScrollDown, vrScrollUp] = await Promise.all<any>([
          new Promise((resolve) => {
            krpano.call("addhotspot(vr-poi-scrolldown)");
            const vrScrollDown = krpano.get("hotspot[vr-poi-scrolldown]")!;

            vrScrollDown.type = "image";
            vrScrollDown.vr = true;
            vrScrollDown.width = 32;
            vrScrollDown.height = 32;
            vrScrollDown.zorder = 61;
            vrScrollDown.ath = ath;
            vrScrollDown.atv = atv;
            vrScrollDown.oy = 20;
            vrScrollDown.ox = (hs.width as number) / 2 + 2;
            vrScrollDown.edge = "lefttop";
            vrScrollDown.distorted = true;
            vrScrollDown.onloaded = () => resolve(vrScrollDown);
            vrScrollDown.url = `data:image/svg+xml;utf8,${encodeURIComponent(arrowDown)}`;
            vrScrollDown.handcursor = false;
          }),
          new Promise((resolve) => {
            krpano.call("addhotspot(vr-poi-scrollup)");
            const vrScrollUp = krpano.get("hotspot[vr-poi-scrollup]")!;

            vrScrollUp.type = "image";
            vrScrollUp.vr = true;
            vrScrollUp.width = 32;
            vrScrollUp.height = 32;
            vrScrollUp.zorder = 61;
            vrScrollUp.ath = ath;
            vrScrollUp.atv = atv;
            vrScrollUp.oy = -20;
            vrScrollUp.ox = (hs.width as number) / 2 + 2;
            vrScrollUp.edge = "leftbottom";
            vrScrollUp.distorted = true;
            vrScrollUp.onloaded = () => resolve(vrScrollUp);
            vrScrollUp.url = `data:image/svg+xml;utf8,${encodeURIComponent(arrowUp)}`;
            vrScrollUp.handcursor = false;
            vrScrollUp.visible = false;
          }),
        ]);

        const scrollDownHandler = () => {
          vrScrollDown.enabled = hasControllers; // Don't disable when pointers are available
          scrollDown();
          vrScrollUp.visible = true;
          vrScrollUp.enabled = true;

          if (top < totalHeight - viewportHeight) {
            requestAnimationFrame(() => requestAnimationFrame(() => (vrScrollDown.enabled = true)));
          } else {
            vrScrollDown.visible = false;
          }
        };

        const scrollUpHandler = () => {
          vrScrollUp.enabled = hasControllers; // Don't disable when pointers are available
          scrollUp();
          vrScrollDown.visible = true;
          vrScrollDown.enabled = true;

          if (top) {
            requestAnimationFrame(() => requestAnimationFrame(() => (vrScrollUp.enabled = true)));
          } else {
            vrScrollUp.visible = false;
          }
        };

        if (hasControllers) {
          vrScrollDown.onclick = scrollDownHandler;
          vrScrollUp.onclick = scrollUpHandler;
        } else {
          vrScrollDown.onover = scrollDownHandler;
          vrScrollUp.onover = scrollUpHandler;
        }
      }

      await new Promise<void>((resolve) => {
        krpano.call("addhotspot(vr-poi-close)");
        const vrCloseHotspot = krpano.get("hotspot[vr-poi-close]")!;

        vrCloseHotspot.type = "image";
        vrCloseHotspot.vr = true;
        vrCloseHotspot.width = 32;
        vrCloseHotspot.height = 32;
        vrCloseHotspot.zorder = 61;
        vrCloseHotspot.ath = ath;
        vrCloseHotspot.atv = atv;
        vrCloseHotspot.oy = -viewportHeight / viewportToHotspotSizeRatio / 2 + 2;
        vrCloseHotspot.ox = (hs.width as number) / 2 + 2;
        vrCloseHotspot.edge = "lefttop";
        vrCloseHotspot.distorted = true;
        vrCloseHotspot.url = `data:image/svg+xml;utf8,${encodeURIComponent(close)}`;
        vrCloseHotspot.onloaded = () => resolve();
      });

      resolve();
    };

    hs.url = url;
    if (hs.loaded) hs.onloaded();
  });
};

export const removeVrPoiHotspot = (krpano: KrpanoElement) => {
  krpano.call(`
    removehotspot(vr-poi);
    removehotspot(vr-poi-control-container);
    removehotspot(vr-poi-close);
    removehotspot(vr-poi-scrolldown);
    removehotspot(vr-poi-scrollup);
  `);
};

export const getSlugWithoutIndexSuffix = (hotspot: Hotspot) => hotspot.slug.replace(/-\d+$/, "");

export const getPoiIcon = (type: MarkerTypes, color?: string) => {
  let icon = defaultMarker;
  let defaultColor = getTokenValue("--color-informative-50");
  switch (type) {
    case MarkerTypes.Pano:
      icon = photoMarker;
      defaultColor = getTokenValue("--color-primary-50");
      break;
    case MarkerTypes.Video:
      icon = videoMarker;
      defaultColor = getTokenValue("--color-primary-50");
      break;
    case MarkerTypes.Vcr:
      icon = vcrMarker;
      defaultColor = getTokenValue("--color-primary-50");
      break;
    case MarkerTypes.Faq:
      icon = userPin;
      break;
    case MarkerTypes.Edit:
      icon = editorMarker;
      defaultColor = getTokenValue("--color-g");
      break;
    case MarkerTypes.Question:
    case MarkerTypes.Info:
    case MarkerTypes.Default:
    default:
      icon = defaultMarker;
  }
  const val = color ? getTokenValue(color) : defaultColor;
  if (val) {
    icon = icon.replace("#1f3be6", val);
  }
  return icon;
};

export const getPanoIcon = (
  heightType: "high" | "low",
  iconType: Exclude<Pano["icon"], "auto">,
  color: string
) => {
  let icon = "";
  switch (iconType) {
    case "pano":
      icon = heightType === "high" ? photoMarkerHigh : photoMarker;
      break;
    case "video":
      icon = heightType === "high" ? videoMarkerHigh : videoMarker;
      break;
    case "vcr":
      icon = heightType === "high" ? vcrMarkerHigh : vcrMarker;
      break;
  }

  return icon.replace("#1f3be6", color);
};

const getSurveyPin = (color?: string) => {
  return color ? defaultMarker.replace("#1f3be6", color) : defaultMarker;
};

export const getCoordinates = (krpano: KrpanoElement) => {
  const { x, y } = krpano.get("mouse");
  return krpano.screentosphere(x, y);
};

function getExtension(filename: string) {
  var parts = filename.split(".");
  return parts[parts.length - 1];
}

export const isWordpressPointHotspot = (
  hotspot: WordpressHotspot
): hotspot is WordpressPointHotspot => !hotspot.hotspot_type || hotspot.hotspot_type === "point";
export const isWordpressPolylineHotspot = (
  hotspot: WordpressHotspot
): hotspot is WordpressPolylineHotspot => hotspot.hotspot_type === "polyline";
export const isWordpressPolygonHotspot = (
  hotspot: WordpressHotspot
): hotspot is WordpressPolygonHotspot => hotspot.hotspot_type === "polygon";

export const isPointHotspot = (
  link:
    | PanoLink<keyof PanoHotspotTypeMap>
    | PanoPoi<keyof PanoHotspotTypeMap>
    | PanoAction<keyof PanoActionTypeMap, keyof PanoHotspotTypeMap>
): link is PanoLink<"point"> | PanoPoi<"point"> | PanoAction<keyof PanoActionTypeMap, "point"> =>
  (link as PanoPoi<keyof PanoHotspotTypeMap>).hotspotType === "point" || link.type === "point";

export const isPolylineHotspot = (
  link:
    | PanoLink<keyof PanoHotspotTypeMap>
    | PanoPoi<keyof PanoHotspotTypeMap>
    | PanoAction<keyof PanoActionTypeMap, keyof PanoHotspotTypeMap>
): link is
  | PanoLink<"polyline">
  | PanoPoi<"polyline">
  | PanoAction<keyof PanoActionTypeMap, "polyline"> =>
  (link as PanoPoi<keyof PanoHotspotTypeMap>).hotspotType === "polyline" ||
  link.type === "polyline";

export const isPolygonHotspot = (
  link:
    | PanoLink<keyof PanoHotspotTypeMap>
    | PanoPoi<keyof PanoHotspotTypeMap>
    | PanoAction<keyof PanoActionTypeMap, keyof PanoHotspotTypeMap>
): link is
  | PanoLink<"polygon">
  | PanoPoi<"polygon">
  | PanoAction<keyof PanoActionTypeMap, "polygon"> =>
  (link as PanoPoi<keyof PanoHotspotTypeMap>).hotspotType === "polygon" || link.type === "polygon";

export const isWordpressSwitchScenarioHotspot = (
  actionHotspot: WordpressPanoAction<keyof WordpressPanoActionTypeMap>
): actionHotspot is WordpressPanoAction<"switch-scenario"> =>
  actionHotspot.action === "switch-scenario";

export const isWordpressOpenUrlHotspot = (
  actionHotspot: WordpressPanoAction<keyof WordpressPanoActionTypeMap>
): actionHotspot is WordpressPanoAction<"open-url"> => actionHotspot.action === "open-url";

export const isWordpressOpenThreeSceneHotspot = (
  actionHotspot: WordpressPanoAction<keyof WordpressPanoActionTypeMap>
): actionHotspot is WordpressPanoAction<"open-three-scene"> =>
  actionHotspot.action === "open-three-scene";

export const isSwitchScenarioHotspot = (
  actionHotspot: PanoAction<keyof PanoActionTypeMap, keyof PanoHotspotTypeMap>
): actionHotspot is PanoAction<"switch-scenario", keyof PanoHotspotTypeMap> =>
  actionHotspot.action === "switch-scenario";

export const isOpenUrlHotspot = (
  actionHotspot: PanoAction<keyof PanoActionTypeMap, keyof PanoHotspotTypeMap>
): actionHotspot is PanoAction<"open-url", keyof PanoHotspotTypeMap> =>
  actionHotspot.action === "open-url";

export const isOpenThreeSceneHotspot = (
  actionHotspot: PanoAction<keyof PanoActionTypeMap, keyof PanoHotspotTypeMap>
): actionHotspot is PanoAction<"open-three-scene", keyof PanoHotspotTypeMap> =>
  actionHotspot.action === "open-three-scene";
