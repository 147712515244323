import { useGeneratePath } from "./useGeneratePath";

export function useContentUrl() {
  const generatePath = useGeneratePath();

  const contentUrl = (contentSlug?: string) => {
    return generatePath(
      {
        contentSlug: contentSlug || undefined,
        faqSlug: undefined,
        poiSlug: undefined,
      },
      true,
      true
    );
  };

  return contentUrl;
}
