export enum SurveyState {
  Closed,
  Open,
}

export enum QuestionnaireType {
  Questionnaire,
  ContactForm,
}

export enum RestrictionLayerType {
  Mandatory,
  Restricted,
}

export enum PermissionOperation {
  READ = 1,
  CREATE,
  UPDATE,
  DELETE,
}

export enum PermissionTypeName {
  Questionnaire = "Questionnaire",
  Response = "Response",
  ApiKey = "ApiKey",
}

export enum TrackingType {
  Query,
  Cookie,
  LocalStorage,
  SessionStorage,
  Custom
}

export enum PanoramaType {
  Cube,
  Sphere
}
