import haversine from "haversine";
import { LineLayerSpecification, SymbolLayerSpecification } from "mapbox-gl";
import { useState } from "react";
import {
  Layer,
  Marker as MapMarker,
  MarkerDragEvent,
  Source,
} from "react-map-gl";
import styled from "styled-components";
import { getTokenValue } from "../../TokenStyles";

type Props = {
  initialPos1: { latitude: number; longitude: number } | null;
  initialPos2: { latitude: number; longitude: number } | null;
};

const layerStyle: Partial<LineLayerSpecification> = {
  id: "point",
  type: "line",
  paint: {
    get "line-color"() { return getTokenValue("--color-primary-50"); },
  },
};

const distanceStyle: Partial<SymbolLayerSpecification> = {
  id: "distance",
  type: "symbol",
  paint: {
    get "text-color"(){ return getTokenValue("--color-black"); },
    get "text-halo-color"(){ return getTokenValue("--color-white"); },
    "text-halo-width": 1.5,
    "text-opacity-transition": { duration: 0 },
    "text-opacity": 1,
  },
  layout: {
    "text-field": ["get", "description"],
    "text-variable-anchor": ["top", "left"],
    "text-radial-offset": 0.5,
    "text-justify": "center",
  },
};

const formatDistance = (distance: number) => {
  if (distance < 1) {
    return (distance * 1000).toFixed(1) + "m";
  } else {
    return distance.toFixed(2) + "km";
  }
};

export const MeasureMarkers = ({ initialPos1, initialPos2 }: Props) => {
  const [location1, setLocation1] = useState<{ latitude: number; longitude: number } | null>(null);
  const [location2, setLocation2] = useState<{ latitude: number; longitude: number } | null>(null);

  const dragFn1 = (e: MarkerDragEvent) => {
    setLocation1({ latitude: e.lngLat.lat, longitude: e.lngLat.lng });
  };
  const dragFn2 = (e: MarkerDragEvent) => {
    setLocation2({ latitude: e.lngLat.lat, longitude: e.lngLat.lng });
  };

  const coordinate1 = location1 ?? initialPos1;
  const coordinate2 = location2 ?? initialPos2;

  const coordinates = [coordinate1, coordinate2]
    .filter((c) => c !== null)
    .map((c) => [c!.longitude, c!.latitude]);

  const geojson = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: coordinates,
        },
      },
      {
        type: "Feature",
        properties: {
          description:
            coordinate1 && coordinate2 ? formatDistance(haversine(coordinate1!, coordinate2!)) : "",
        },
        geometry: {
          type: "Point",
          coordinates:
            coordinate1 && coordinate2
              ? [
                  (coordinate1.longitude + coordinate2.longitude) * 0.5,
                  (coordinate1.latitude + coordinate2.latitude) * 0.5,
                ]
              : null,
        },
      },
    ],
  };

  return (
    <>
      {/** @ts-ignore */}
      <Source id="measure-layer" type="geojson" data={geojson}>
        <Layer {...layerStyle as any} />
        <Layer {...distanceStyle as any} />
      </Source>
      {coordinate1 && (
        <MapMarker
          draggable
          latitude={coordinate1.latitude}
          longitude={coordinate1.longitude}
          onDragEnd={dragFn1}
          onDrag={dragFn1}
          offset={[8, 8]}
        >
          <MeasureMarkerIcon />
        </MapMarker>
      )}
      {coordinate2 && (
        <MapMarker
          draggable
          latitude={coordinate2.latitude}
          longitude={coordinate2.longitude}
          onDragEnd={dragFn2}
          onDrag={dragFn2}
          offset={[8, 8]}
        >
          <MeasureMarkerIcon />
        </MapMarker>
      )}
    </>
  );
};

const MeasureMarkerIcon = styled.div`
  width: var(--space-30);
  height: var(--space-30);
  background: var(--color-primary-50);
  border: 2px solid white;
  border-radius: 50%;
  box-shadow: var(--box-shadow-25);
  transform: translate(-50%, -50%);
`;
