import { useCallback, useMemo, useState } from "react";
import { findAnswer, isLocationQuestion, LocalPinModel, useSurveyState } from "../SurveyContext";
import { Marker as MapMarker, MarkerDragEvent, useMap } from "react-map-gl";
import { MarkerIconStyles } from "../components/Map/MapStyles";
import { DefaultMarker } from "../components/Map/DefaultMarker";
import {
  GeoLocationPinModel,
} from "./EndUserApi";
import { useRouteState } from "../hooks/useRouteState";
import { useAppState } from "../AppContext";
import { SurveyWarningModal } from "../components/Modals/SurveyWarningModal";
import { RestrictionLayerType } from "./enums";

export const SurveyMapMarkers = () => {
  const { surveyState, openModalFn, setSurveyState } = useSurveyState();
  const map = useMap();
  const { state } = useAppState();
  const [surveyWarningModalMessages, setSurveyWarningModalMessages] = useState<string[]>([]);

  const closeSurveyWarningModal = useCallback(() => setSurveyWarningModalMessages([]), []);

  const getLayerIdBySlug = useCallback(
    (slug?: string) => state.map.layerGroups.find((layer: any) => layer.slug === slug)?.id ?? 0,
    [state.map?.layerGroups]
  );

  const getScenarioIdBySlug = useCallback(
    (slug: string) => state.scenarios.find((scenario) => scenario.slug === slug)?.id ?? 0,
    [state.scenarios]
  );

  const { activeLayerGroupSlug, activeScenarioSlug } = useRouteState();

  const activeLocationQuestions = useMemo(
    () => surveyState.activePage?.elements.filter(isLocationQuestion) ?? [],
    [surveyState.activePage]
  );

  const activeAnswers = useMemo(
    () =>
      activeLocationQuestions.flatMap((q) =>
        surveyState.answers.filter(
          findAnswer(q)
        )
      ),
    [activeLocationQuestions, surveyState.answers]
  );

  const markers = useMemo(
    () =>
      activeAnswers
        .flatMap((a) => a.pins.filter((p): p is GeoLocationPinModel => p.type === "GeoLocation"))
        .filter(
          (p) =>
            p.layerId === surveyState.activePage?.dataSource?.id &&
            p.scenarioId === getScenarioIdBySlug(activeScenarioSlug)
        )
        .map((p) => p as LocalPinModel),
    [activeAnswers, surveyState.activePage?.dataSource, activeScenarioSlug, getScenarioIdBySlug]
  );

  const getPinColor = useCallback(
    (pinId: number) => {
      if (!activeLocationQuestions) return;
      const question = activeLocationQuestions.find((q) => q.pins.some((p) => p.id === pinId));
      const pin = question?.pins.find((p) => p.id === pinId);
      return pin?.color ?? undefined;
    },
    [activeLocationQuestions]
  );

  const onMarkerDragEnd = useCallback(
    (event: MarkerDragEvent, pin: LocalPinModel) => {
      const currentMap = map.current!.getMap();

      const answer = activeAnswers.find((a) =>
        a.pins.some((p) => (p as LocalPinModel).id === pin.id)
      );
      const question = activeLocationQuestions?.find((e) => {
        return answer?.questionId === e.id;
      });

      if (!question || !answer) return;

      const restrictions = question.restrictions.filter((r) =>
        r.pins.includes(answer.pins[0].pinDefinition)
      );

      if (restrictions.length) {
        const features = currentMap.queryRenderedFeatures(
          map.current!.project([event.lngLat.lng, event.lngLat.lat])
        );

        const mandatoryLayers = restrictions.filter(
          (r) => r.restrictionType === RestrictionLayerType.Mandatory
        );

        const onRestrictedLayers = restrictions.filter((r) =>
          features.some((f) => f.layer && r.cluster.includes(f.layer.id))
        );

        const warnings = new Array<string>();

        if (
          mandatoryLayers.length &&
          !mandatoryLayers.some((l) => onRestrictedLayers.includes(l))
        ) {
          warnings.push(...mandatoryLayers.map((l) => l.message));
        }

        warnings.push(
          ...onRestrictedLayers
            .filter((l) => l.restrictionType === RestrictionLayerType.Restricted)
            .map((l) => l.message)
        );

        setSurveyWarningModalMessages(warnings);

        return;
      }

      pin.latitude = event.lngLat.lat;
      pin.longitude = event.lngLat.lng;
      setSurveyState({
        ...surveyState,
        answers: surveyState.answers.slice(),
      });
    },
    [activeAnswers, activeLocationQuestions, map, setSurveyState, surveyState]
  );

  return (
    <>
      {markers.map((m) => (
        <MapMarker
          latitude={m.latitude}
          longitude={m.longitude}
          key={m.id}
          offset={[5, -10]}
          onClick={() => openModalFn(m.id)}
          draggable
          onDragEnd={(event) => onMarkerDragEnd(event, m)}
        >
          <div>
            <MarkerIconStyles className="">
              <DefaultMarker color={getPinColor(m.pinDefinition)} />
            </MarkerIconStyles>
          </div>
        </MapMarker>
      ))}
      <SurveyWarningModal
        open={!!surveyWarningModalMessages.length}
        onClose={closeSurveyWarningModal}
        warnings={surveyWarningModalMessages}
      />
    </>
  );
};
