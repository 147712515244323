import styled, { css } from "styled-components";
import { breakpoints } from "../../utils/styleUtils";
import { Link } from "react-router-dom";
import Icon from "../../shared/components/Icon";

const contentHiddenState = css`
  opacity: 0;
  transform: scale(0.95);

  @media ${breakpoints[30]} {
    transform: translateX(-16px) scale(1);

    &.no-sidebar {
      transform: translateX(calc((-1 * var(--sidebar-width) + 10px) - 16px)) scale(1);
    }
  }
`;

const contentVisibleState = css`
  opacity: 1;
  transform: translateX(0);

  @media ${breakpoints[20]} {
    transform: translateX(0);

    &.no-sidebar {
      transform: translateX(calc(-1 * var(--sidebar-width) + 10px)) scale(1);
    }
  }
`;

export const StyledLayoutContent = styled.div`
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  z-index: 60;
  overflow: hidden;

  opacity: 0;
  pointer-events: none;

  transition: transform 300ms var(--bezier-a), opacity 200ms var(--bezier-a);
  transform: scale(0.95);

  @media ${breakpoints[20]} {
    transition: transform 300ms var(--bezier-a), opacity 200ms var(--bezier-a);
    transform: translateX(16px) scale(1);
    padding: 8px;
    grid-column: 2 / 3;
  }

  ${contentVisibleState}

  &.animate-enter {
    ${contentHiddenState}
  }

  &.animate-enter-active,
  &.animate-exit {
    ${contentVisibleState}
  }

  &.animate-exit-active {
    ${contentHiddenState}
  }
`;

export const StyledContent = styled.div`
  max-height: 100%;
  background: var(--color-white);
  box-shadow: var(--box-shadow-30);
  position: relative;
  pointer-events: auto;
  overflow-y: auto;

  @media ${breakpoints[20]} {
    border-radius: var(--border-radius-default);
  }
`;

export const ContentScroll = styled.div`
  width: 100%;
  height: auto;
  overflow: hidden auto;

  &.off-screen {
    position: absolute;
    top: 105vh;
  }

  &.rendering-vr {
    width: fit-content;
    background: inherit;
    border-radius: inherit;

    iframe,
    video,
    audio,
    .vr-hide {
      display: none;
    }

    .vr-warning {
      border: solid 1px var(--color-informative-50);
      border-radius: var(--border-radius-default);
      padding: var(--space-default);
      color: var(--color-neutral-50);
    }

    img {
      width: 70ch;
      height: auto;
    }
  }
`;

export const ContentHeader = styled.div`
  position: relative;
  padding: var(--space-inset-default);
  
  > img {
    width: 100%;
    border-radius: var(--border-radius-20);
    cursor: pointer;
  }

  &.crop {
    height: 16vw;
    min-height: 200px;

    > img {
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &:first-child {
    padding-top: 0;
  }
`;

export const ContentBody = styled.div`
  max-width: 80ch;
  width: 100%;
  margin: auto;
  padding: var(--space-inset-default);

  > :first-child {
    max-width: calc(100% - 3rem);
  }
`;

export const CloseIconSpan = styled(Icon)`
  margin: auto;
  width: 16px;
  height: 16px;

  & svg {
    width: 16px;
    height: 16px;
    stroke: var(--color-white);
    stroke-width: 2px;
    transition: stroke 200ms var(--bezier-a);
  }
`;

export const ContentCloseLink = styled(Link)`
  width: 24px;
  height: 24px;
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  background-color: rgba(25, 24, 37, 0.8);
  transition: background-color 200ms var(--bezier-a);

  &:hover {
    background-color: rgba(25, 24, 37, 0.95);
  }
`;

export const TitleBar = styled.div`
  display: flex;
  z-index: 1;
  justify-content: space-between;
  align-items: flex-start;
  padding: var(--space-default);
  position: sticky;
  top: 0;
  background: var(--color-white);

  > h1 {
    margin: 0;
  }
`;