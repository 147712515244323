import React, { useState, useRef, useEffect, useLayoutEffect, useCallback, useMemo } from "react";
import ReactMapGL, { NavigationControl, MapRef, ViewStateChangeEvent } from "react-map-gl";
import { useAppState } from "../../AppContext";
import { Route, useHistory } from "react-router-dom";
import Poi from "../Map/Poi";
import { activateLayerGroupFromScenarioAndSlug } from "../../utils/layerGroupUtils";
import LegendItems from "../Map/LegendItems";
import { useRouteState } from "../../hooks/useRouteState";
import IsDesktop from "../../shared/components/IsDeskop";
import { calculateViewport, flyToDuration } from "../../utils/mapUtils";
import userPin from "../../images/markers/pinUser.svg";
import { QuestionMarker } from "../Map/QuestionMarker";
import { getLabelsOnPoint } from "../../utils/poiUtils";
import { isMapTourPoint } from "../../utils/tourUtils";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../utils/styleUtils";
import mapboxgl, { MapLayerMouseEvent } from "mapbox-gl";
import { useInterfaceState } from "../../InterfaceContext";
import { MapMarkers } from "../Map/MapMarkers";
import { MemoizedMapActions } from "../Map/MapActions";
import { usePanoUrl } from "../../hooks/usePanoUrl";
import { useParticipationItemUrl } from "../../hooks/useParticipationItemUrl";
import { useMapState } from "../../hooks/useMapState";
import { useSetMapState } from "../../hooks/useSetMapState";
import { useUrlState } from "../../hooks/useUrlState";
import { useContentUrl } from "../../hooks/useContentUrl";
import { ReservedMenuIndices } from "../../utils/menuUtils";
import { MeasureControl } from "../Map/Controls/MeasureControl";
import { MeasureMarkers } from "../Map/MeasureMarkers";
import { LegendControl } from "../Map/Controls/LegendControl";
import { useFeature, Feature } from "../../hooks/useFeature";
import WebMercatorViewport from "viewport-mercator-project";
import { PanoMapContainer } from "./PanoStyles";
import {
  isLayerSource,
  isPanoramaSource,
  PlaceGeoPinOptions,
  useSurveyState,
} from "../../SurveyContext";
import { SurveyMapMarkers } from "../../tim-survey/SurveyMapMarkers";
import { useCopy } from "../../hooks/useCopy";
import { useIntl } from "react-intl";
import { messages } from "../../global-intl-messages";
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

type PrevMapState = {
  lat: number;
  long: number;
  zoom: number;
};

const customAttribution =
  '<a href="https://www.theimagineers.com/" target="_blank">© The Imagineers</a>';

// const lockedFrameRate = 1;

export const PanoMap = () => {
  const { state } = useAppState();
  const { interfaceState, setInterfaceState, participationState } = useInterfaceState();

  const panoUrl = usePanoUrl();
  const contentUrl = useContentUrl();
  const participationItemUrl = useParticipationItemUrl();
  const mapState = useMapState();
  const routeState = useRouteState(true);
  const setMapState = useSetMapState();
  const urlState = useUrlState();
  const history = useHistory<{ latestPanoSlug?: string; mapMove?: boolean }>();
  const isBigScreen = useMediaQuery({ query: breakpoints["map"] });
  const { pinPlaceFn, surveyState } = useSurveyState();
  const copy = useCopy();
  const intl = useIntl();
  const [mapControlsTranslated, setMapControlsTranslated] = useState(false);

  const [sidebarWasOpen] = useState(interfaceState.sidebarOpen);
  const [loaded, setLoaded] = useState(false);
  const [initialGroupLayerApplied, setInitialGroupLayerApplied] = useState(false);
  const [isOnFeature, setIsOnFeature] = useState(false);
  const [measuring, setMeasuring] = useState(false);
  const [measurePos1, setMeasurePos1] = useState<{ latitude: number; longitude: number } | null>(
    null
  );
  const [measurePos2, setMeasurePos2] = useState<{ latitude: number; longitude: number } | null>(
    null
  );

  const measuringAvailable = useFeature(Feature.Measuring);
  const legendAvailable = useFeature(Feature.Legend);
  const participationAvailable = useFeature(Feature.Participation);
  const askQuestionAvailable = useFeature(Feature.AskQuestion);

  const mapRef = useRef<MapRef>(null);
  const map = mapRef.current?.getMap();

  const prevMapState = useRef<PrevMapState>({
    lat: 0,
    long: 0,
    zoom: 1,
  });

  // const krpano = useMemo(() => document.querySelector<KrpanoElement>("#" + krpanoId), []);

  // const lockFrameRate = useCallback(() => {
  //   if (krpano) {
  //     krpano.blur();
  //     krpano.set("display.framerate", lockedFrameRate);
  //     krpano.call("stopmovements(); freezeview(true); autorotate.pause();");
  //   }
  // }, [krpano]);

  // const freeFrameRate = useCallback(() => {
  //   if (krpano) {
  //     krpano.set("display.framerate", "auto");
  //     krpano.call("freezeview(false); autorotate.resume();");
  //   }
  // }, [krpano]);

  // useLayoutEffect(() => {
  //   lockFrameRate();
  //   return freeFrameRate;
  // }, [lockFrameRate, freeFrameRate]);

  const layerSource = useMemo(
    () =>
      surveyState.activePage?.dataSource && isLayerSource(surveyState.activePage.dataSource)
        ? surveyState.activePage.dataSource
        : null,
    [surveyState.activePage?.dataSource]
  );

  const styleId = useMemo(
    () => (layerSource?.styleId ? `mapbox://styles/${layerSource.styleId}` : state.map.style),
    [state.map.style, layerSource]
  );

  const accessToken = useMemo(
    () => layerSource?.accessToken ?? state.map.accessToken,
    [state.map.style, layerSource]
  );

  const interactiveLayers = useMemo(() => {
    const layers = new Set<string>();

    if (layerSource) {
      layerSource.clusters.forEach((l) => layers.add(l));
    } else {
      state.map.layerGroups
        .flatMap((m) => m.scenarios.flatMap((s) => s.combinedLayers.flat()))
        .forEach((l) => layers.add(l));
    }

    return Array.from(layers);
  }, [layerSource, state.map.layerGroups]);

  useEffect(() => setLoaded(false), [accessToken]);

  useEffect(() => {
    return history.listen((h) => {
      setInterfaceState((state) => {
        if (state.legendClicked && h.state?.mapMove) return { ...state, legendClicked: undefined };
        return state;
      });
    });
  }, [history, setInterfaceState]);

  useEffect(() => {
    if (!measuring) {
      setMeasurePos1(null);
      setMeasurePos2(null);
    }
  }, [measuring]);

  const mapboxContainer = mapRef.current?.getContainer();

  // HACK: Translate mapbox controls
  useEffect(() => {
    const controls = mapboxContainer?.querySelector(".mapboxgl-control-container");
    if (!mapControlsTranslated && controls) {
      const zoomInAriaLabel = controls.querySelector(".mapboxgl-ctrl-zoom-in");
      const zoomInButton = zoomInAriaLabel?.firstElementChild;
      const zoomOutAriaLabel = controls.querySelector(".mapboxgl-ctrl-zoom-out");
      const zoomOutButton = zoomOutAriaLabel?.firstElementChild;

      const translatedZoomIn = intl.formatMessage(messages.zoomIn);
      const translatedZoomOut = intl.formatMessage(messages.zoomOut);

      zoomInAriaLabel?.setAttribute("aria-label", translatedZoomIn);
      zoomInButton?.setAttribute("title", translatedZoomIn);

      zoomOutAriaLabel?.setAttribute("aria-label", translatedZoomOut);
      zoomOutButton?.setAttribute("title", translatedZoomOut);

      setMapControlsTranslated(true);
    }
  }, [intl, mapControlsTranslated, setMapControlsTranslated, mapboxContainer]);

  useEffect(() => {
    return () =>
      setInterfaceState((currentState) => ({ ...currentState, sidebarOpen: sidebarWasOpen }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [viewport, setViewport] = useState<any>(() => {
    const { innerWidth, innerHeight } = window;
    // Return if we have a state from the url
    if (mapState) {
      return new WebMercatorViewport({
        latitude: mapState.lat,
        longitude: mapState.long,
        zoom: mapState.zoom,
        width: innerWidth - 32,
        height: innerHeight - 32,
      });
    } else {
      return calculateViewport(state.map.initialBounds);
    }
  });

  const updateUrl = useCallback(
    (e: ViewStateChangeEvent) => {
      const { latitude, longitude, zoom } = e.viewState;
      if (latitude && longitude && zoom) {
        setMapState(latitude, longitude, zoom);
        prevMapState.current = {
          lat: +latitude.toFixed(4),
          long: +longitude.toFixed(4),
          zoom: +zoom.toFixed(4),
        };
      }
    },
    [setMapState]
  );

  const setMeasurePositions = useCallback(
    (event: MapLayerMouseEvent) => {
      if (!measurePos1) {
        setMeasurePos1({ latitude: event.lngLat.lat, longitude: event.lngLat.lng });
      } else if (measurePos1 && !measurePos2) {
        setMeasurePos2({ latitude: event.lngLat.lat, longitude: event.lngLat.lng });
      }
    },
    [measurePos1, measurePos2]
  );

  const handleMapClick = useCallback(
    (event: MapLayerMouseEvent) => {
      const tourPoint = state.tourPoints.find((p) => p.slug === routeState.tourPointSlug);
      const activeLayerSlug = routeState.activeLayerGroupSlug;
      const scenarioSlug = routeState.activeScenarioSlug;
      let layerSlug = state.map.defaultLayerGroupSlug;

      if (tourPoint && isMapTourPoint(tourPoint)) {
        layerSlug = tourPoint.data.layerGroup.slug;
      } else if (activeLayerSlug) {
        layerSlug = activeLayerSlug;
      }

      if (measuring) setMeasurePositions(event);

      const geoPin: PlaceGeoPinOptions = {
        location: { longitude: event.lngLat.lng, latitude: event.lngLat.lat },
        layer: layerSlug ?? "",
        scenario: scenarioSlug,
        featuresAtLocation: event.features ?? [],
      };
      pinPlaceFn(geoPin);

      // Copy location on click for dev purposes
      if (event.originalEvent.ctrlKey) {
        const value = `${event.lngLat.lat},${event.lngLat.lng}`;
        copy(`${event.lngLat.lat},${event.lngLat.lng}`).then(
          () => console.info(`Coördinates copied to clipboard: (${value})`, "zoom:", viewport.zoom),
          () => console.info(`Çopy failed: Clicked Lat Long: ${value}`, "Zoom:", viewport.zoom)
        );
      }

      setInterfaceState({
        ...interfaceState,
        legendClicked: !document.getElementById("question-el")
          ? {
              features: event.features,
              lngLat: event.lngLat,
            }
          : undefined,
      });
      const mapClick = {
        latitude: event.lngLat.lat,
        longitude: event.lngLat.lng,
        layerSlug,
      };
      document.dispatchEvent(new CustomEvent("mapclick", { detail: mapClick }));
      const clickedFeatureLabel = getLabelsOnPoint(
        [event.lngLat.lat, event.lngLat.lng],
        event.target
      )[0];

      switch (clickedFeatureLabel?.properties!.type) {
        case "pano":
        case "video":
          history.push(panoUrl(clickedFeatureLabel.properties.slug), {
            latestPanoSlug: clickedFeatureLabel.properties.slug,
          });
          break;
        case "info":
          history.push(contentUrl(clickedFeatureLabel.properties.slug));
          break;
        case "question":
          history.push(participationItemUrl(clickedFeatureLabel.properties.slug));
          break;
      }
    },
    [
      state.tourPoints,
      state.map.defaultLayerGroupSlug,
      routeState.activeLayerGroupSlug,
      routeState.activeScenarioSlug,
      routeState.tourPointSlug,
      measuring,
      setMeasurePositions,
      pinPlaceFn,
      setInterfaceState,
      interfaceState,
      viewport.zoom,
      history,
      panoUrl,
      contentUrl,
      participationItemUrl,
      copy,
    ]
  );

  const handleMapMouseMove = useCallback(
    (event: MapLayerMouseEvent) => {
      if (document.getElementById("question-el")) return;

      const currentIsOnFeature = Boolean(
        getLabelsOnPoint([event.lngLat.lat, event.lngLat.lng], event.target).length
      );

      setIsOnFeature(currentIsOnFeature);
    },
    [isOnFeature]
  );

  // Only use this effect if the layer group is updated
  useLayoutEffect(() => {
    if (loaded && map && !layerSource) {
      if (routeState.tourPointSlug || routeState.tourSlug) {
        const tourPoint = state.tourPoints.find((p) => p.slug === routeState.tourPointSlug);
        if (!tourPoint || !isMapTourPoint(tourPoint)) {
          if (routeState.activeLayerGroupSlug)
            activateLayerGroupFromScenarioAndSlug(
              routeState.activeLayerGroupSlug,
              routeState.activeScenarioSlug,
              state.map.layerGroups,
              map
            );
        } else {
          // Map tourpoint
          if (tourPoint.data.boundingBox) {
            const targetViewport = calculateViewport(tourPoint.data.boundingBox);
            setViewport((current: any) => ({
              ...current,
              longitude: targetViewport.longitude,
              // transitionDuration: flyToDuration(current, targetViewport),
              latitude: targetViewport.latitude,
              zoom: targetViewport.zoom,
            }));
          }

          activateLayerGroupFromScenarioAndSlug(
            tourPoint.data.layerGroup.slug,
            tourPoint.scenarioSlug,
            state.map.layerGroups,
            map
          );
        }
        if (!initialGroupLayerApplied) setInitialGroupLayerApplied(true);
      } else if (participationState.layerSlug) {
        activateLayerGroupFromScenarioAndSlug(
          participationState.layerSlug,
          participationState.scenarioSlug || routeState.activeScenarioSlug,
          state.map.layerGroups,
          map
        );
        if (!initialGroupLayerApplied) setInitialGroupLayerApplied(true);
      } else if (routeState.activeLayerGroupSlug) {
        activateLayerGroupFromScenarioAndSlug(
          routeState.activeLayerGroupSlug,
          routeState.activeScenarioSlug,
          state.map.layerGroups,
          map,
          initialGroupLayerApplied && state.mapConfig.autozoomToActiveLayers ? map.flyTo : undefined
        );
        if (!initialGroupLayerApplied) setInitialGroupLayerApplied(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    routeState.layerGroupSlug,
    routeState.scenarioSlug,
    routeState.tourPointSlug,
    routeState.tourSlug,
    participationState.layerSlug,
    loaded,
    map,
    layerSource,
    state.mapConfig.autozoomToActiveLayers,
    setViewport,
  ]);

  useEffect(() => {
    if (layerSource && map?.isStyleLoaded()) {
      const { east, north, west, south } = layerSource.bounds;
      const targetViewport = calculateViewport([
        [east, north],
        [west, south],
      ]);

      setViewport((current: any) => ({
        ...current,
        ...targetViewport,
        transitionDuration: flyToDuration(current, targetViewport),
      }));

      map
        .getStyle()!
        .layers.filter(
          (l: any) =>
            l.type !== "background" &&
            !l.source?.startsWith("mapbox://") &&
            !l.metadata?.hasOwnProperty("mapbox:featureComponent")
        )
        .forEach((layer) => {
          map.setLayoutProperty(
            layer.id,
            "visibility",
            layerSource.clusters.includes(layer.id) ? "visible" : "none"
          );
        });
    }
  }, [layerSource, setViewport, map?.isStyleLoaded()]);

  useEffect(() => {
    if (mapState && prevMapState.current) {
      const prev = prevMapState.current;
      // Only move if mapstate has been induced by other actions than moving the map itself
      if (mapState.lat !== prev.lat || mapState.long !== prev.long || mapState.zoom !== prev.zoom) {
        if (map) {
          map.flyTo(
            {
              center: [mapState.long, mapState.lat],
              zoom: mapState.zoom,
            },
            { duration: 2000 }
          );
        }
        prevMapState.current = {
          lat: mapState.lat,
          long: mapState.long,
          zoom: mapState.zoom,
        };
      }
    } else if (!mapState && urlState.selectedNavigationItem === ReservedMenuIndices.Home) {
      const newViewport = calculateViewport(state.map.initialBounds);
      prevMapState.current = {
        lat: viewport.latitude!,
        long: viewport.longitude!,
        zoom: viewport.zoom!,
      };
      setViewport({
        ...newViewport,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapState, map, urlState.selectedNavigationItem]);

  const questionMarkerPaths = useMemo(() => {
    const paths = [];
    if (askQuestionAvailable) paths.push("*/question");
    if (participationAvailable) paths.push("*/participation/");
    return paths;
  }, [askQuestionAvailable, participationAvailable]);

  return (
    <PanoMapContainer data-html2canvas-ignore>
      <ReactMapGL
        {...viewport}
        key={accessToken} // HACK: Forces s full reload of mapbox on accessToken change, as mapbox only uses it on initial load.
        ref={mapRef}
        customAttribution={customAttribution}
        mapboxAccessToken={accessToken}
        mapStyle={styleId}
        minZoom={state.mapConfig.minZoom}
        maxZoom={state.mapConfig.maxZoom}
        onClick={handleMapClick}
        onMouseMove={handleMapMouseMove}
        cursor={interfaceState.question?.pickingLocation ? `url(${userPin}) 14 43, auto` : "auto"}
        interactiveLayerIds={interactiveLayers}
        onLoad={() => setLoaded(true)}
        onMoveEnd={updateUrl}
        onMove={(e) => setViewport(e.viewState)}
      >
        {loaded && (
          <>
            <MemoizedMapActions />
            {/** pois, markers etc. */}
            <MapMarkers viewport={viewport} />
            <SurveyMapMarkers />
            {measuringAvailable && measuring && (
              <MeasureMarkers initialPos1={measurePos1} initialPos2={measurePos2} />
            )}
            {(askQuestionAvailable || participationAvailable) && (
              <Route path={questionMarkerPaths}>
                {interfaceState.question?.location && (
                  <QuestionMarker {...interfaceState.question.location} />
                )}
              </Route>
            )}
            <IsDesktop>
              {(!surveyState.activePage?.dataSource ||
                !isPanoramaSource(surveyState.activePage.dataSource)) && <Poi />}
            </IsDesktop>
            <LegendItems forMap />
            <div style={{ position: "absolute", right: 44, bottom: isBigScreen ? 92 : 96 }}>
              <NavigationControl showCompass={false} position="bottom-right" />
              {measuringAvailable && (
                <MeasureControl
                  onClick={() => setMeasuring(!measuring)}
                  active={measuring}
                  pointsPlaced={!!(measurePos1 && measurePos2)}
                />
              )}
              {legendAvailable && <LegendControl />}
            </div>
          </>
        )}
      </ReactMapGL>
    </PanoMapContainer>
  );
};
