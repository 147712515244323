import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import Button from "../shared/components/Button";
import { Modal } from "../shared/components/Modal";
import { isLocationQuestion, LocalPinModel, useSurveyState } from "../SurveyContext";
import { CreateLocationPickerAnswerModel } from "./EndUserApi";

type Props = {
  open: boolean;
  onClose: () => void;
  answerId: number | null;
};

export const SurveyArgumentModal = (props: Props) => {
  const { onClose, answerId } = props;

  const { surveyState, setSurveyState } = useSurveyState();
  const [comment, setComment] = useState("");

  const activeLocationQuestionIds = useMemo(
    () => surveyState.activePage?.elements.filter(isLocationQuestion).map((q) => q.id),
    [surveyState.activePage]
  );

  const activeAnswers = useMemo(
    () =>
      surveyState.answers.filter(
        (a): a is CreateLocationPickerAnswerModel =>
          activeLocationQuestionIds?.includes(a.questionId) ?? false
      ),
    [activeLocationQuestionIds, surveyState.answers]
  );

  const answer = useMemo(
    () => activeAnswers.find((a) => a.pins.some((p) => (p as LocalPinModel).id === answerId)),
    [activeAnswers, answerId]
  );

  const pin = useMemo(
    () => answer?.pins.find((p) => (p as LocalPinModel).id === answerId),
    [answer, answerId]
  );

  const removePin = useCallback(() => {
    if (!pin || !answer) return;
    setSurveyState((prevState) => {
      answer.pins = answer.pins.filter((p) => p !== pin);

      return {
        ...prevState,
        answers: prevState.answers.slice(),
      };
    });
    onClose();
  }, [answer, pin, onClose, setSurveyState]);

  useEffect(() => setComment(pin?.comment ?? ""), [pin]);

  const updateText = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setComment(e.target.value),
    [setComment]
  );

  const storeComment = useCallback(() => {
    if (!pin) return;
    pin.comment = comment;
    setSurveyState((current) => ({
      ...current,
      answers: current.answers.slice(),
      modalOpen: false,
    }));
    setComment("");
    onClose();
  }, [pin, setSurveyState, setComment, comment, onClose]);

  return (
    <Modal {...props}>
      <h2>
        <FormattedMessage
          id="survey.argument-model.explain.title"
          defaultMessage="Explain your answer"
        />
      </h2>
      <p>
        <FormattedMessage
          id="survey.argument-model.explain.description"
          defaultMessage="In the box below, please explain your answer."
        />
      </p>
      <input type="text" onChange={updateText} defaultValue={comment} />
      <ButtonContainer>
        <Button onClick={() => removePin()}>
          <FormattedMessage id="survey.argument-model.delete" defaultMessage="Delete" />
        </Button>
        <Button className="primary" onClick={() => storeComment()}>
          <FormattedMessage id="survey.argument-model.save" defaultMessage="Save" />
        </Button>
      </ButtonContainer>
    </Modal>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;
