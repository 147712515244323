import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import { SurveyArgumentModal } from "./tim-survey/SurveyArgumentModal";
import {
  EndUserApi as Api,
  BooleanQuestionModel,
  ChoiceQuestionModel,
  CreateAnswerModel,
  CreateLocationPickerAnswerModel,
  ElementModel,
  FileUploadEndpointReferenceModel,
  FileUploadQuestionModel,
  GeoLocationPinModel,
  HttpResponse,
  LocationPickerQuestionModel,
  PanoLocationPinModel,
  QuestionModel,
  QuestionnaireModel,
  RatingQuestionModel,
  TextQuestionModel,
  TrackingQuestionModel,
  PageModel,
  DataSourceModel,
  LayerSourceModel,
  PanoramaSourceModel,
  CreateTextAnswerModel,
  CreateBooleanAnswerModel,
  CreateChoiceAnswerModel,
  CreateRatingAnswerModel,
  CreateTrackingAnswerModel,
  CreateFileUploadAnswerModel,
} from "./tim-survey/EndUserApi";
import usePromise from "./hooks/usePromise";
import { useAppState } from "./AppContext";
import { UseStateReturn } from "./types";
import { Capability, useCapability } from "./hooks/useCapability";
import { useIntl } from "react-intl";
import { SurveyWarningModal } from "./components/Modals/SurveyWarningModal";
import { RestrictionLayerType } from "./tim-survey/enums";

export function isLocationQuestion(element: ElementModel): element is LocationPickerQuestionModel {
  return element.type === "LocationPickerQuestion";
}

export function isTextQuestion(element: ElementModel): element is TextQuestionModel {
  return element.type === "TextQuestion";
}

export function isBooleanQuestion(element: ElementModel): element is BooleanQuestionModel {
  return element.type === "BooleanQuestion";
}

export function isRatingQuestion(element: ElementModel): element is RatingQuestionModel {
  return element.type === "RatingQuestion";
}

export function isChoiceQuestion(element: ElementModel): element is ChoiceQuestionModel {
  return element.type === "ChoiceQuestion";
}

export function isFileUploadQuestion(element: ElementModel): element is FileUploadQuestionModel {
  return element.type === "FileUploadQuestion";
}

export function isTrackingQuestion(element: ElementModel): element is TrackingQuestionModel {
  return element.type === "TrackingQuestion";
}

export function isElementQuestion(element: ElementModel): element is ElementModel {
  return element.type === "Element";
}
export function isQuestion(element: ElementModel): element is QuestionModel {
  return element.type.includes("Question");
}

export const isLayerSource = (element: DataSourceModel): element is LayerSourceModel =>
  element.type === "LayerSource";
export const isPanoramaSource = (element: DataSourceModel): element is PanoramaSourceModel =>
  element.type === "PanoramaSource";

// prettier-ignore
type ToAnswerType<T extends QuestionModel> =
    T extends TextQuestionModel ? CreateTextAnswerModel
  : T extends BooleanQuestionModel ? CreateBooleanAnswerModel
  : T extends ChoiceQuestionModel ? CreateChoiceAnswerModel
  : T extends RatingQuestionModel ? CreateRatingAnswerModel
  : T extends TrackingQuestionModel ? CreateTrackingAnswerModel
  : T extends LocationPickerQuestionModel ? CreateLocationPickerAnswerModel
  : T extends FileUploadQuestionModel ? CreateFileUploadAnswerModel
  : CreateAnswerModel;

/**
 * Typed predicate factory to retrieve answers on a question, based on the passed question
 *
 * @returns a type predicate that can be used to filter answers by question id
 */
export const findAnswer =
  <Q extends QuestionModel = QuestionModel, A extends CreateAnswerModel = ToAnswerType<Q>>(
    element: Q
  ) =>
  (a: CreateAnswerModel): a is A =>
    a.questionId === element.id;

export type LocalPinModel = (PanoLocationPinModel | GeoLocationPinModel) & { id: number };

enum ApiErrorType {
  SingleEntryPolicyViolation = "https://docs.theimagineers.com/errors/survey/single-entry-policy-violation",
  NotFound = "https://tools.ietf.org/html/rfc7231#section-6.5.4",
  Unauthorized = "https://tools.ietf.org/html/rfc7235#section-3.1",
}

const surveyUrl = () => {
  const localhost = /^(localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(:\d+)?$/.exec(
    window.location.host
  );
  if (localhost) {
    return process.env.REACT_APP_SURVEY_API_HOST || `https://${localhost[1]}:45011`;
  } else if (window.location.host.includes("staging-ik-doe-mee")) {
    return "https://app-tim-survey-end-user-api-cd-westeu-00-development.azurewebsites.net";
  }
  return "https://api.survey.theimagineers.com";
};

const toSourcePlaceholderMap: Record<string, string> = {
  s: "f",
};
const fromSourcePlaceholderMap: Record<string, string> = Object.fromEntries(
  Object.entries(toSourcePlaceholderMap).map((r) => r.reverse())
);

export const toPanoSourceImageUrl = (panoUrl: string) =>
  panoUrl.replace(/%(\w)/g, ($0, $1) => `{${toSourcePlaceholderMap[$1] ?? $1}}`);
export const fromPanoSourceImageUrl = (panoUrl: string) =>
  panoUrl.replace(/\{(\w)\}/g, ($0, $1) => `%${fromSourcePlaceholderMap[$1] ?? $1}`);

type SurveyState = {
  activePage?: PageModel;
  survey?: QuestionnaireModel;
  activeQuestionId?: string;
  activePinId?: number;
  questions: LocationPickerQuestionModel[];
  modalOpen: boolean;
  answers: CreateAnswerModel[];
  files?: Record<number, FileList | null>;
};

// @ts-ignore
const initialSurveyState: SurveyState = {
  activePage: undefined,
  survey: undefined,
  activeQuestionId: undefined,
  activePinId: undefined,
  questions: [],
  modalOpen: false,
  answers: [],
};

interface PlacePinOptions {
  location: { latitude: number; longitude: number };
  scenario: string;
}
export interface PlaceGeoPinOptions extends PlacePinOptions {
  layer: string;
  featuresAtLocation: mapboxgl.MapboxGeoJSONFeature[];
}
export interface PlacePanoPinOptions extends PlacePinOptions {
  pano: string;
}
const isPlaceGeoPinOptions = (options: PlacePinOptions): options is PlaceGeoPinOptions =>
  options.hasOwnProperty("layer");
const isPlacePanoPinOptions = (options: PlacePinOptions): options is PlacePanoPinOptions =>
  options.hasOwnProperty("pano");

const SurveyStateContext = createContext<{
  surveyState: SurveyState;
  setSurveyState: Dispatch<SetStateAction<SurveyState>>;
  pinPlaceFn: (options: PlacePinOptions) => void;
  openModalFn: (id: number) => void;
  onCompleteFn: () => Promise<HttpResponse<FileUploadEndpointReferenceModel[], any>>;
  setActiveSurvey: UseStateReturn<number>[1];
  api: Api<unknown>;
  error?: { title: string; message: string };
  setError: UseStateReturn<{ title: string; message: string } | undefined>[1];
}>({
  surveyState: initialSurveyState,
  setSurveyState: () => {},
  pinPlaceFn: () => {},
  openModalFn: () => {},
  onCompleteFn: () => Promise.reject(),
  setActiveSurvey: () => {},
  api: new Api<unknown>(),
  setError: () => {},
});

// Fallback for when third-party cookies are not usable (e.g. in incognito mode)
const completedSurveyIds = new Set<number>();

export const SurveyStateProvider = ({ children }: any) => {
  const [activeSurvey, setActiveSurvey] = useState(0);
  const [surveyState, setSurveyState] = useState(initialSurveyState);
  const { state } = useAppState();
  const [lastAnswerId, setLastAnswerId] = useState<number | null>(null);
  const [surveyWarningModalMessages, setSurveyWarningModalMessages] = useState<string[]>([]);
  const [error, setError] = useState<{ title: string; message: string }>();
  const intl = useIntl();
  const excemptFromSingleEntryPolicy = useCapability(Capability.ReadDraft);

  const getLayerIdBySlug = useCallback(
    (slug: string) => state.map.layerGroups.find((layer: any) => layer.slug === slug)?.id ?? 0,
    [state.map?.layerGroups]
  );

  const getScenarioIdBySlug = useCallback(
    (slug: string) => state.scenarios.find((scenario: any) => scenario.slug === slug)?.id ?? 0,
    [state.scenarios]
  );

  const getPanoIdBySlug = useCallback(
    (slug: string) => state.panos.find((pano: any) => pano.slug === slug)?.id ?? 0,
    [state.panos]
  );

  const apiKey = useMemo(
    () => state.participation?.find((p) => p.surveyId === `tim.survey://${activeSurvey}`)?.apiKey,
    [state.participation, activeSurvey]
  );

  const api = useMemo(() => {
    return new Api({
      baseUrl: surveyUrl(),
      baseApiParams: {
        credentials: excemptFromSingleEntryPolicy ? "omit" : "include",
      },
      securityWorker() {
        if (apiKey) return { headers: { "X-ApiKey": apiKey } };
      },
    });
  }, [excemptFromSingleEntryPolicy, apiKey]);

  const [survey] = usePromise(async () => {
    if (!activeSurvey) return;

    let r: HttpResponse<QuestionnaireModel, any>;
    if (completedSurveyIds.has(activeSurvey) && !excemptFromSingleEntryPolicy) {
      // Incognito mode fallback
      r = { ok: false, error: { type: ApiErrorType.SingleEntryPolicyViolation } } as HttpResponse<
        any,
        any
      >;
    } else {
      r = await api.surveys.getSurvey(activeSurvey).catch((r) => r);
    }

    if (!r.ok) {
      switch (r.error.type) {
        case ApiErrorType.SingleEntryPolicyViolation:
          setError({
            title: intl.formatMessage({
              id: "survey.errors.single-entry-policy-violation.title",
              defaultMessage: "Survey completed already",
              description:
                "Error title when loading a survey fails due to the survey being completed before.",
            }),
            message: intl.formatMessage({
              id: "survey.errors.single-entry-policy-violation.message",
              defaultMessage: "You have already participated in this survey.",
              description:
                "Error message when loading a survey fails due to the survey being completed before.",
            }),
          });
          completedSurveyIds.add(activeSurvey);
          break;

        case ApiErrorType.NotFound:
          setError({
            title: intl.formatMessage({
              id: "survey.errors.not-found.title",
              defaultMessage: "Survey not found",
              description:
                "Error title when loading a survey fails due to it not being available in the current platform.",
            }),
            message: intl.formatMessage({
              id: "survey.errors.not-found.message",
              defaultMessage: "The survey you are trying to participate in does not exist.",
              description:
                "Error message when loading a survey fails due to it not being available in the current platform.",
            }),
          });
          break;

        default:
          setError({
            title: intl.formatMessage({
              id: "survey.errors.default.title",
              defaultMessage: "An error occurred",
              description: "Default error title when loading a survey fails.",
            }),
            message:
              r.error.detail ||
              intl.formatMessage({
                id: "survey.errors.default.message",
                defaultMessage: "The survey you are trying to participate in failed to load.",
                description: "Default error message when loading a survey fails.",
              }),
          });
      }

      setActiveSurvey(0);

      return;
    }

    return r.data;
  }, [api.surveys, activeSurvey, excemptFromSingleEntryPolicy, intl]);

  const onComplete = useCallback(async () => {
    if (!activeSurvey) return Promise.reject();

    const result = await api.surveys.createResponse(activeSurvey, {
      answers: surveyState.answers,
    });

    if (!excemptFromSingleEntryPolicy) {
      completedSurveyIds.add(activeSurvey);
    }

    setActiveSurvey(0);

    return result;
  }, [
    api.surveys,
    surveyState.answers,
    activeSurvey,
    setActiveSurvey,
    excemptFromSingleEntryPolicy,
  ]);

  useEffect(() => {
    if (survey && activeSurvey) {
      setSurveyState((s) => ({ ...s, activePage: survey.pages[0], survey }));
    } else {
      setSurveyState((s) => ({ ...s, activePage: undefined, survey: undefined }));
    }
  }, [survey, activeSurvey]);

  const closeModal = useCallback(() => {
    setSurveyState((prevState) => ({ ...prevState, modalOpen: false }));
    setLastAnswerId(null);
  }, [setSurveyState, setLastAnswerId]);

  const activeLocationQuestions = useMemo(
    () => surveyState.activePage?.elements.filter(isLocationQuestion),
    [surveyState.activePage]
  );

  const activeLocationAnswers = useMemo(
    () =>
      surveyState.answers.filter(
        (a): a is CreateLocationPickerAnswerModel =>
          activeLocationQuestions?.some((q) => q.id === a.questionId) ?? false
      ),
    [activeLocationQuestions, surveyState.answers]
  );

  const openModal = useCallback(
    (answerId: number) => {
      const answer = activeLocationAnswers.find((a) =>
        a.pins.some((p) => (p as LocalPinModel).id === answerId)
      );
      const pin = answer?.pins.find((p) => (p as LocalPinModel).id === answerId);
      const question = activeLocationQuestions?.find((e) => e.id === answer?.questionId);
      if (!question) return;
      const questionPin = question.pins.find((p) => p.id === pin?.pinDefinition);
      if (!questionPin?.allowComment) return;
      setLastAnswerId(answerId);
      setSurveyState((prevState) => ({
        ...prevState,
        modalOpen: true,
      }));
    },
    [activeLocationAnswers, activeLocationQuestions, setLastAnswerId, setSurveyState]
  );

  const panoUrl = useCallback(
    (resource: string) =>
      toPanoSourceImageUrl(`${origin}/panoramas/${state.panoramaFolder}/${resource}/mres_%s/l%l/%v/l%l_%s_%v_%h.jpg`),
    [origin, state.panoramaFolder]
  );

  const placePin = useCallback(
    (options: PlacePinOptions) => {
      if (!surveyState.activePinId || !surveyState.survey) return;
      const question = activeLocationQuestions?.find((e) => {
        return e.pins.some((p) => p.id === surveyState.activePinId);
      });
      if (!question) return;

      const pin = question.pins.find((p) => p.id === surveyState.activePinId);
      if (!pin) return;
      const id = Date.now();
      const answer = activeLocationAnswers.find((a) => a.questionId === question.id) ?? {
        questionId: question.id,
        type: "LocationPickerAnswer",
        pins: [],
      };

      if (isPlaceGeoPinOptions(options)) {
        const restrictions = question.restrictions.filter((r) => r.pins.includes(pin.id));

        if (restrictions.length) {
          const mandatoryLayers = restrictions.filter(
            (r) => r.restrictionType === RestrictionLayerType.Mandatory
          );

          const onRestrictedLayers = restrictions.filter((r) =>
            options.featuresAtLocation.some((f) => f.layer && r.cluster.includes(f.layer.id))
          );

          const warnings = new Array<string>();

          if (
            mandatoryLayers.length &&
            !mandatoryLayers.some((l) => onRestrictedLayers.includes(l))
          ) {
            warnings.push(...mandatoryLayers.map((l) => l.message));
          }

          warnings.push(
            ...onRestrictedLayers
              .filter((l) => l.restrictionType === RestrictionLayerType.Restricted)
              .map((l) => l.message)
          );

          setSurveyWarningModalMessages(warnings);

          if (warnings.length) return;
        }

        let layerSource: LayerSourceModel;

        if (
          surveyState.activePage?.dataSource &&
          isLayerSource(surveyState.activePage.dataSource)
        ) {
          layerSource = surveyState.activePage.dataSource;
        } else {
          layerSource =
            surveyState.survey.dataSources
              .filter(isLayerSource)
              .find((l) =>
                options.featuresAtLocation.every((f) => f.layer && l.clusters.includes(f.layer.id))
              ) ?? surveyState.survey.dataSources.filter(isLayerSource)[0];
          if (!layerSource) {
            console.error("No layersource found");
            return;
          }
        }

        const placedPin: GeoLocationPinModel & { id: number } = {
          latitude: options.location.latitude,
          longitude: options.location.longitude,
          comment: "",
          pinDefinition: pin.id,
          id: id,
          layerId: layerSource.id,
          scenarioId: getScenarioIdBySlug(options.scenario),
          type: "GeoLocation",
        };

        answer.pins.push(placedPin);
      } else if (isPlacePanoPinOptions(options)) {
        let panoramaSource: PanoramaSourceModel;

        if (
          surveyState.activePage?.dataSource &&
          isPanoramaSource(surveyState.activePage.dataSource)
        ) {
          panoramaSource = surveyState.activePage.dataSource;
        } else {
          const pano = state.panos.find((p) => p.slug === options.pano);

          const panoScenario = pano?.scenarios.find((s) => s.slug === options.scenario);

          if (!panoScenario || panoScenario.isVideo) return;

          const resourceUrl = panoUrl(panoScenario.resource[0]);

          panoramaSource = surveyState.survey.dataSources
            .filter(isPanoramaSource)
            .find((l) => l.imageUrl === resourceUrl)!;

          if (!panoramaSource) {
            console.error("No layersource found");
            return;
          }
        }

        const placedPin: PanoLocationPinModel & { id: number } = {
          latitude: options.location.latitude,
          longitude: options.location.longitude,
          comment: "",
          pinDefinition: pin.id,
          id: id,
          panoId: panoramaSource.id,
          scenarioId: getScenarioIdBySlug(options.scenario),
          type: "PanoLocation",
        };

        answer.pins.push(placedPin);
      }

      const answers = surveyState.answers.map((a) =>
        a.questionId === answer?.questionId ? answer : a
      );
      if (!answers.includes(answer)) {
        answers.push(answer);
      }

      setLastAnswerId(id);
      setSurveyState((prevState) => ({
        ...prevState,
        activePinId: undefined,
        modalOpen: pin.allowComment,
        answers,
      }));
    },
    [
      surveyState.activePinId,
      surveyState.answers,
      activeLocationQuestions,
      activeLocationAnswers,
      getLayerIdBySlug,
      getScenarioIdBySlug,
      getPanoIdBySlug,
    ]
  );

  return (
    <SurveyStateContext.Provider
      value={{
        surveyState,
        setSurveyState,
        pinPlaceFn: placePin,
        openModalFn: openModal,
        onCompleteFn: onComplete,
        setActiveSurvey,
        api,
        error,
        setError,
      }}
    >
      <SurveyArgumentModal
        open={surveyState.modalOpen}
        onClose={closeModal}
        answerId={lastAnswerId}
      />
      <SurveyWarningModal
        open={!!surveyWarningModalMessages.length}
        onClose={() => setSurveyWarningModalMessages([])}
        warnings={surveyWarningModalMessages ?? undefined}
      />
      {children}
    </SurveyStateContext.Provider>
  );
};

export const useSurveyState = () => useContext(SurveyStateContext);
