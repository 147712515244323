import { useAppState } from "../../AppContext";
import { useRouteState } from "../../hooks/useRouteState";
import { PoiContentStyles } from "./MapStyles";
import PoiContent from "../Content/PoiContent";
import { Faq, MapFaqData } from "../../types";
import { poiFromMapFaq } from "../../utils/faqUtils";
import { usePoiUrl } from "../../hooks/usePoiUrl";
import { useHistory } from "react-router-dom";

export default function Poi() {
  const { state } = useAppState();
  const routeState = useRouteState();
  const history = useHistory();
  const poiUrl = usePoiUrl();

  const poiSlug = routeState.poiSlug;
  let poi = state.pois.find((p) => p.slug === poiSlug);

  // @ts-ignore
  if (!poi) poi = state.areas.find((p) => p.slug === poiSlug);

  if (!poi) {
    const faq = state.faqs.filter((f): f is Faq<MapFaqData> => f.type === "map").find((f) => f.slug === poiSlug);
    if (!faq) return null;
    poi = poiFromMapFaq(faq);
  }

  if (!poi || poi.latitude === undefined || poi.longitude === undefined) return null;

  return (
    <PoiContentStyles
      anchor="top"
      offset={[0, 5]}
      latitude={poi.latitude}
      longitude={poi.longitude}
      closeOnClick={false}
      onClose={() => history.replace(poiUrl())}
      className={poi.image ? "with-image" : ""}
      style={{ maxWidth: "unset" }}
    >
      <PoiContent />
    </PoiContentStyles>
  );
}
