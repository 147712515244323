import { FormattedMessage } from "react-intl";
import { messages } from "../../global-intl-messages";
import Button from "../../shared/components/Button";
import { Modal, ModalProps } from "../../shared/components/Modal";

interface Props extends ModalProps {
  warnings: string[];
};

export const SurveyWarningModal = ({ warnings, ...modalProps }: Props) => {
  return (
    <Modal {...modalProps}>
      <FormattedMessage
        id="survey-warning-modal.title"
        defaultMessage="Location not allowed"
        tagName="h1"
      />
      {warnings.map((w, i) => <p key={i}>{w}</p>)}
      <Button onClick={() => modalProps.onClose?.()}>
        <FormattedMessage {...messages.closeButton} />
      </Button>
    </Modal>
  );
};
