import React, { useMemo } from "react";
import { Marker as MapMarker } from "react-map-gl";
import { MapPoi } from "../../types";
import { MarkerIconStyles } from "./MapStyles";
import { useRouteState } from "../../hooks/useRouteState";

import { Link, useLocation } from "react-router-dom";
import { markerSVGSwitch } from "../../shared/components/MarkerImages";
import { usePanoUrl } from "../../hooks/usePanoUrl";
import { useParticipationItemUrl } from "../../hooks/useParticipationItemUrl";
import { useContentUrl } from "../../hooks/useContentUrl";
import classNames from "classnames";
import { useInterfaceState } from "../../InterfaceContext";

type MarkerProps = {
  point: MapPoi;
};

function Marker({ point: poi }: MarkerProps) {
  const { state: locationState } = useLocation<{ latestPanoSlug?: string }>();
  const routeState = useRouteState();
  const { setInterfaceState } = useInterfaceState();
  const contentUrl = useContentUrl();
  const panoUrl = usePanoUrl();
  const participationItemUrl = useParticipationItemUrl();
  const isPano = useMemo(() => ["pano", "video", "vcr"].includes(poi.type), [poi.type]);

  const latestPanoSlug = locationState?.latestPanoSlug;
  const selected = isPano ? poi.slug === latestPanoSlug : poi.slug === routeState.contentSlug;

  const offset = useMemo(() => {
    switch (poi.type) {
      case "pano":
      case "video":
      case "vcr":
        return { offsetLeft: -18, offsetTop: -18 };
      case "faq":
        return { offsetLeft: -22, offsetTop: -37 };
      default:
        return { offsetLeft: -15, offsetTop: -37 };
    }
  }, [poi.type]);

  return (
    <MapMarker
      latitude={poi.latitude}
      longitude={poi.longitude}
      offset={[offset.offsetLeft, offset.offsetTop]}
      anchor="top-left"
    >
      <Link
        to={{
          pathname: isPano
            ? panoUrl(poi.slug)
            : poi.type === "question"
            ? participationItemUrl(poi.slug, true)
            : contentUrl(poi.slug),
          state: isPano ? { latestPanoSlug: poi.slug } : null,
        }}
        onClick={(event) => {
          if (poi.phantom) {
            event.preventDefault();
            setInterfaceState((prevState) => ({
              ...prevState,
              phantomPanoAlertOpen: true,
            }));
          }
        }}
      >
        <div>
          <MarkerIconStyles
            className={classNames({
              phantom: poi.phantom,
              high: poi.height === "high" && isPano,
              question: poi.type === "faq",
            })}
          >
            {markerSVGSwitch(poi, selected, poi.color)}
          </MarkerIconStyles>
        </div>
      </Link>
    </MapMarker>
  );
}

export const MemoizedMarker = React.memo(Marker);
