import React, { forwardRef, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import {
  StyledLayoutContent,
  StyledContent,
  ContentScroll,
  ContentBody,
  ContentHeader,
  ContentCloseLink,
  CloseIconSpan,
  TitleBar,
} from "./ContentStyles";
import { useAppState } from "../../AppContext";
import classNames from "classnames";
import { useRouteState } from "../../hooks/useRouteState";
import { ReactComponent as CloseIcon } from "./../../images/close.svg";
import { ParagraphSmall } from "../../shared/components/ParagraphStyles";
import { getFaqItems } from "../../utils/faqUtils";
import { useInterfaceState } from "../../InterfaceContext";
import { useContentUrl } from "../../hooks/useContentUrl";
import { MemoizedWordpressContentWrapper } from "../../shared/components/TextWrapper";
import { FormattedMessage, useIntl } from "react-intl";
import { toHotspot } from "../../utils/poiUtils";
import { Feature, useFeature } from "../../hooks/useFeature";
import { ImageViewer } from "../../shared/components/ImageViewer";

export const Content = forwardRef<null>((props, ref) => {
  const styledContentRef = useRef<HTMLDivElement | null>(null);
  const vrAvailable = useFeature(Feature.VR);
  const vrRef = useRef<HTMLDivElement | null>(null);
  const { state } = useAppState();
  const { interfaceState, setInterfaceState, isInVr } = useInterfaceState();
  const routeState = useRouteState();
  const contentSlug = routeState.contentSlug;
  const faqSlug = routeState.faqSlug;
  const contentUrl = useContentUrl();
  const intl = useIntl();
  const [selectedHeaderImage, setSelectedHeaderImage] = useState(false);

  const { pois, pages, areas } = state;
  const { layerGroups } = state.map;
  // let fullscreenContent = false;

  const contentObject = useMemo(
    () => [...pois, ...pages, ...areas, ...layerGroups].find((c) => c.slug === contentSlug),
    [pois, pages, areas, layerGroups, contentSlug]
  );

  const isPoi = useMemo(() => pois.includes(contentObject as any), [pois, contentObject]);

  const faqList = useMemo(
    () => (faqSlug ? getFaqItems(faqSlug, state.faqs) : undefined),
    [faqSlug, state.faqs]
  );

  useLayoutEffect(() => {
    if (
      isPoi &&
      vrAvailable &&
      vrRef.current &&
      contentSlug &&
      !interfaceState.vr.pois[contentSlug]
    ) {
      toHotspot(vrRef.current, intl).then((vrHotspotData) => {
        setInterfaceState((prevState) => ({
          ...prevState,
          vr: {
            ...prevState.vr,
            pois: { ...prevState.vr.pois, [contentSlug]: vrHotspotData.objectUrl },
          },
        }));
      });
    }
  }, [contentSlug, interfaceState.vr.pois, setInterfaceState, intl, vrAvailable, isPoi]);

  useEffect(() => {
    if (styledContentRef.current) {
      styledContentRef.current.scrollTop = 0;
    }
  }, [contentSlug, faqSlug]);

  const titleContent = useMemo(
    () =>
      contentObject ? (
        <h1>{contentObject.title}</h1>
      ) : faqList ? (
        <FormattedMessage
          tagName="h1"
          id="content.faq-header"
          defaultMessage="Frequently asked questions"
          description="Header for a FAQ page"
        />
      ) : null,
    [contentObject, faqList]
  );

  const bodyContent = useMemo(
    () =>
      !contentObject && !faqList ? null : (
        <>
          {contentObject && (
            <>
              {contentObject.image && (
                <ContentHeader>
                  <img src={contentObject.image.sizes.medium_large} alt={contentObject.image.alt} onClick={() => setSelectedHeaderImage(true)} />
                </ContentHeader>
              )}
              <ContentBody>
                <MemoizedWordpressContentWrapper content={contentObject.content} />
              </ContentBody>
            </>
          )}
          {faqList && (
            <ContentBody>
              {faqList.map((question, i) => {
                return (
                  <span key={question.question}>
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: question.question.replace(
                          "<p>",
                          `<p>${intl.formatMessage({
                            id: "content.faq-header-question",
                            defaultMessage: "Question",
                            description: "Prefix for a question in a FAQ page",
                          })}: `
                        ),
                      }}
                    ></h3>
                    <ParagraphSmall
                      dangerouslySetInnerHTML={{
                        __html: question.answer.replace(
                          "<p>",
                          `<p>${intl.formatMessage({
                            id: "content.faq-header-answer",
                            defaultMessage: "Answer",
                            description: "Prefix for an answer in a FAQ page",
                          })}: `
                        ),
                      }}
                    ></ParagraphSmall>
                  </span>
                );
              })}
            </ContentBody>
          )}
        </>
      ),
    [contentObject, faqList, intl]
  );

  if (!contentSlug) return null;

  return (
    bodyContent && (
      <StyledLayoutContent
        ref={ref}
        className={classNames({
          "no-sidebar": !interfaceState.sidebarOpen,
          // wide: fullscreenContent,
        })}
      >
        <StyledContent ref={styledContentRef}>
          <TitleBar>
            {titleContent}
            <ContentCloseLink to={contentUrl()}>
              <CloseIconSpan>
                <CloseIcon />
              </CloseIconSpan>
            </ContentCloseLink>
          </TitleBar>
          {/* {!fullscreenContent ? ( */}

          {/* ) : null} */}
          {(!isInVr || !interfaceState.vr.pois[contentSlug]) && (
            <ContentScroll className={isInVr ? "off-screen" : ""} ref={vrRef}>
              {bodyContent}
            </ContentScroll>
          )}
        </StyledContent>
        {!!contentObject?.image?.url && selectedHeaderImage && <ImageViewer url={contentObject.image.url} onClose={() => setSelectedHeaderImage(false)} />}
      </StyledLayoutContent>
    )
  );
});
