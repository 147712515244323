import React, { SyntheticEvent, useCallback, useEffect, useMemo, useRef } from "react";
import { PanoMediaControlsContainer, MediaTimeLine, MediaControl, MediaSpan } from "./PanoStyles";

import { ReactComponent as PauseIcon } from "../../images/pause-button.svg";
import { ReactComponent as PlayIcon } from "../../images/play-arrow.svg";
import { ReactComponent as MuteIcon } from "../../images/mute.svg";
import { ReactComponent as UnmuteIcon } from "../../images/unmute.svg";
import poiPause from "../../images/markers/poiPause.svg";
import poiPlay from "../../images/markers/poiPlay.svg";
import { ratioToPercentage, toTimeDisplay } from "../../utils/mediaUtils";
import { useRerender } from "../../hooks/useRerender";
import { useTimeout } from "../../hooks/useTimeout";
import { FormattedMessage, useIntl } from "react-intl";
import { useSessionStorage } from "../../hooks/useStorage";
import { KrpanoElement } from "../../libs/krpano";

interface PanoVideoControlsProps {
  krpano: KrpanoElement;
  showVideoControls: boolean;
}

const muteKey = "muteMedia";

export function PanoVideoControls({ krpano, showVideoControls }: PanoVideoControlsProps) {
  const isMounted = useRef(true);
  const rerender = useTimeout(useRerender(isMounted), isMounted, 100);
  const [mutedRaw, setMuted] = useSessionStorage(muteKey);
  const intl = useIntl();

  const vrPlayButton = krpano.get("hotspot[toggle-play]");
  const vrEnabled: boolean = krpano.get("webvr.isenabled");
  const videoPlugin = krpano.get("plugin[video]");

  const muted = useMemo(() => (mutedRaw ? JSON.parse(mutedRaw) : false), [mutedRaw]);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  useEffect(() => {
    if (videoPlugin?.videoDOM && !videoPlugin.destroyPatched) {
      videoPlugin.destroy = (
        (n: (...args: any[]) => any) =>
        (...args: any[]) => {
          videoPlugin.videoDOM.src = null;
          videoPlugin.videoDOM.load();
          n.call(videoPlugin, ...args);
        }
      )(videoPlugin.destroy);
      videoPlugin.destroyPatched = true;
    }
  }, [videoPlugin, videoPlugin?.videoDOM, videoPlugin?.destroyPatched]);

  useEffect(() => {
    if (videoPlugin && muted !== null && videoPlugin.muted !== muted) {
      videoPlugin.muted = muted;
    }
  }, [muted, videoPlugin]);

  const togglePlaying = useCallback(() => {
    videoPlugin?.togglepause();
    rerender();
  }, [videoPlugin, rerender]);

  const seek = useCallback(
    (event: SyntheticEvent<HTMLElement, MouseEvent>) => {
      const ratio = event.nativeEvent.offsetX / (event.target as HTMLElement).clientWidth;
      videoPlugin?.seek(ratio * videoPlugin.totaltime);
      rerender();
    },
    [videoPlugin, rerender]
  );

  const toggleMute = useCallback(() => {
    setMuted(`${!videoPlugin?.muted}`);
  }, [videoPlugin, setMuted]);

  useEffect(() => {
    window.addEventListener("keydown", togglePlaying, false);

    return () => {
      window.removeEventListener("keydown", togglePlaying, false);
    };
  }, [togglePlaying]);

  useEffect(() => {
    if (vrPlayButton && videoPlugin) {
      vrPlayButton.ath = krpano.get("view.hlookat");
      vrPlayButton.atv = krpano.get("view.vlookat") + 20;
      vrPlayButton.visible = showVideoControls && vrEnabled;
      vrPlayButton.url = videoPlugin.ispaused ? poiPause : poiPlay;
    }
  }, [vrEnabled, vrPlayButton, showVideoControls, videoPlugin, krpano]);

  useEffect(() => {
    if (isMounted.current && videoPlugin) {
      if (videoPlugin.ispaused) rerender.override(500);
      else rerender();
    }
  }, [videoPlugin, rerender]);

  const unmuteLabel = intl.formatMessage({
    id: "pano.media.unmute-aria-label",
    defaultMessage: "Turn off sound",
    description: "ARIA label for the mute button while the sound is currently muted.",
  });
  const muteLabel = intl.formatMessage({
    id: "pano.media.mute-aria-label",
    defaultMessage: "Turn on sound",
    description: "ARIA label for the mute button while the sound is not currently muted.",
  });
  const pauseLabel = intl.formatMessage({
    id: "pano.media.pause-aria-label",
    defaultMessage: "Pause",
    description: "ARIA label for the pause button.",
  });
  const playLabel = intl.formatMessage({
    id: "pano.media.play-aria-label",
    defaultMessage: "Play",
    description: "ARIA label for the play button.",
  });

  return (
    videoPlugin && (
      <PanoMediaControlsContainer>
        <MediaTimeLine onClick={seek}>
          <div
            style={{ width: `${ratioToPercentage(videoPlugin.time, videoPlugin.totaltime)}%` }}
          />
        </MediaTimeLine>
        <MediaControl onClick={togglePlaying}>
          {!videoPlugin.ispaused ? (
            <PauseIcon title={pauseLabel} aria-label={pauseLabel} />
          ) : (
            <PlayIcon title={playLabel} aria-label={playLabel} />
          )}
        </MediaControl>
        <MediaControl onClick={toggleMute}>
          {videoPlugin.muted ? (
            <MuteIcon title={unmuteLabel} aria-label={unmuteLabel} />
          ) : (
            <UnmuteIcon title={muteLabel} aria-label={muteLabel} />
          )}
        </MediaControl>
        <MediaSpan>
          {toTimeDisplay(videoPlugin.time)} / {toTimeDisplay(videoPlugin.totaltime)}
        </MediaSpan>
        <MediaSpan>
          <FormattedMessage
            id="pano.media-type.video"
            defaultMessage="Video"
            description="Label depicting the currently playing media is a panoramic video."
          />
        </MediaSpan>
      </PanoMediaControlsContainer>
    )
  );
}
